import { Link } from '@chakra-ui/core';
import { auth } from 'hooks/auth';
import React from 'react';
import { NavLink } from "react-router-dom";
import { getSlug } from 'utils/helper';
import CarouselFirstCard from '../CarouselFirstCard';
import { CarouselCards, FadeBlock } from "../CarouselWebdoor";
import { CarouselCardProps } from "./types";

interface Props {
  card: CarouselCardProps;
  index: number;
  cardsLength: number;
  handleSlideClick: (card: CarouselCardProps, pageX: any) => void;
  setMouseXpos: React.Dispatch<React.SetStateAction<number>>;
}

export function CarouselSmartCard({ card, index, handleSlideClick, setMouseXpos, cardsLength }: Props) {
  const slug = getSlug().url;
  const { auth: _isAuth } = auth();
  const isAuth = _isAuth.isAuthenticated;

  function handleInternalUrl() {
    if (
      localStorage.getItem("query_shell") !== "null" &&
      localStorage.getItem("query_shell")?.length > 1
    ) {
      return `${slug}${card?.url}?shellbox=${localStorage.getItem(
        "query_shell"
      )}&token=${localStorage.getItem("external_token")}`;
    } else {
      return `${slug}${card?.url}`;
    }
  }

  if (isAuth && (index === cardsLength - 1)) {
    return (
      <CarouselFirstCard
        key={'firstCard'}
      />
    )
  }

  if ((card?.url && !card?.nova_janela && !card?.sso) && (card?.url[0] === "/")) {
    return (
      <NavLink
        key={card?.imagem_fundo}
        tabIndex={0}
        to={handleInternalUrl()}
        target={card?.nova_janela ? "_blank" : ""}
        rel={"noopener noreferer"}
      >
        <CarouselCards
          bgImg={card?.imagem_fundo}
        >
          <FadeBlock hasDesc={Boolean(card.descricao)}>
            {card.titulo && <strong>{card?.titulo}</strong>}
            {card.descricao && <p>{card?.descricao}</p>}
          </FadeBlock>
        </CarouselCards>
      </NavLink>
    )
  }

  // Se for popup, link externo ou sso
  return (
    <CarouselCards
      key={card?.imagem_fundo}
      bgImg={card?.imagem_fundo}
      style={{
        cursor: (!card?.url && !card?.popup && !card?.sso) ? "default" : "pointer"
      }}
      tabIndex={0}
      // onClick={(e) => handleSlideClick(card, e.pageX)}
      onMouseDown={(e) => {
        setMouseXpos(e.pageX);
      }}
      onMouseUp={(e) => {
        handleSlideClick(card, e.pageX);
      }}
      onKeyPress={(e) =>
        e.key === "Enter" && handleSlideClick(card, 0)
      }
    >
      <FadeBlock hasDesc={Boolean(card.descricao)}>
        {card.titulo && <strong>{card?.titulo}</strong>}
        {card.descricao && <p>{card?.descricao}</p>}
      </FadeBlock>
    </CarouselCards>
  )
}