import React from "react";
import styled from "styled-components";

interface ActivePromotionStampProps {
  top?: string;
  left: string;
  responsiveLeftPosition?: string;
  responsiveTopPosition?: string;
  isPromoActive?: boolean;
}
// Selo de promoção ativa
// Precisa ser colocado dentro de um elemento que possua "position: relative"
export function ActivePromotionStamp({
  left,
  top,
  responsiveLeftPosition,
  responsiveTopPosition,
  isPromoActive
}: ActivePromotionStampProps) {
  return (
    <Container
      top={top}
      left={left}
      responsiveLeftPosition={responsiveLeftPosition}
      responsiveTopPosition={responsiveTopPosition}
      isPromoActive={isPromoActive}
    >
      {isPromoActive ? (
        <>campanha<span>ativa</span></>
      ) : (
        <>campanha encerrada</>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.isPromoActive ? "#FFF" : props.theme.color.darkGray};
  color: ${props => props.isPromoActive ? props => props.theme.color.red : "#FFF"};
  
  font-family: "Futura LT Bold";
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-transform: uppercase;

  border-radius: ${props => !props.isPromoActive && "9px"};

  width: 3.75rem;
  height: 1.25rem;

  padding: 0.875rem;

  position: absolute;
  top: ${(props) => (props.top !== undefined ? props.top : "0")};
  left: ${(props) => props.left};

  box-shadow: -4px 4px 2px rgb(0, 0, 0, 0.2);

  span {
    font-size: ${(props) => (props.isActive ? "1.375rem" : "0.938rem")};
    line-height: 1rem;
    letter-spacing: 1;
  }

  box-sizing: unset;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    top: ${(props) =>
      props.responsiveTopPosition !== undefined
        ? props.responsiveTopPosition
        : null};
    left: ${(props) =>
      props.responsiveLeftPosition !== undefined
        ? props.responsiveLeftPosition
        : null};
  }
`;
