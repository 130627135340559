import React from "react";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";

//assets
// import bannerMobile from "assets/banners/coxinha-mobile.png";
// import banner from "assets/banners/coxinha-desktop.png";
import bannerMobile from "assets/banners/banner-carnaval-mobile.png";
import banner from "assets/banners/banner-carnaval.png";
import { ActivePromotionStamp } from "../ActivePromotionStamp";

const Banner = () => {
  const { width } = useWindowSize();

  return (
    <Container>{/* src={width <= 440 ? bannerMobile : banner}>*/}
      <img src={width <= 440 ? bannerMobile : banner} alt="banner" />
      {/* <ButtonSaibaMais href={'/ofertas-shell-select/detalhe'}>
        Saiba Mais
      </ButtonSaibaMais> */}
      {/* <ActivePromotionStamp top={"0.5rem"} left={"8rem"} responsiveTopPosition={"1.5%"} responsiveLeftPosition={"10%"} /> */}
      {/* left padrao: left={"11.5%"} */}
      <ActivePromotionStamp top={"0.5rem"} left={"23.5%"} responsiveTopPosition={"1.5%"} responsiveLeftPosition={"50%"} />
    </Container>
  );
}

export default Banner;

const Container = styled.div`
  margin-top: 2rem;
  height: 496px;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  position: relative;

  @media (min-width: 376px) {
    background-size: 27rem 100%;
  }

  @media (min-width: 441px) {
    height: 22rem;
    margin-top: 2.75rem;
    background-size: 64rem 100%;
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    margin-top: 5rem;
    height: 24.75rem;
    background-position: top;
    background-size: 90rem 100%;
  }

  @media (min-width: 1441px) {
    background-size: cover;
  }
  
  @media (min-width: 1600px) {
    height: 38rem;
    background-size: 160rem cover;
  }

  img {
    width: 100%;
    height: inherit;
  }
  /* @media(max-width: 1500px){
    height: 396px;
  }

  @media(max-width: ${props => props.theme.queries.medium}){
    background-size: contain;
    height: 193px;
    margin-top: 40px;
  }

  @media(max-width: ${props => props.theme.queries.small}){
    height: 480px;
    margin-top: 40px;
  } */
`;

const ButtonSaibaMais = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-decoration: none;

  width: 10.5rem;
  height: 3rem;

  border: 0;
  background: ${props => props.theme.color.red};
  color: #FFF;
  text-transform: uppercase;

  position: absolute;
  top: 12rem;

  cursor: pointer;
  margin: 1.25rem;
  transition: 0.2s;

  &:hover, &:focus {
    background: ${props => props.theme.color.orange};
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;

    left: 1rem;
    top: 26rem;
    
    width: 26rem;
    height: 3.5rem;
  }
  @media (min-width: 1280px) {
    left: 8rem;
  }
`;