import React from "react";
import styled from "styled-components";
import useWindowSize from "hooks/useWIndowSize";
import { theme } from "theme";
import { HeaderBlock } from "./HeaderBlock";
import { Box, Text } from "@chakra-ui/core";
import { TextCustom } from "./TextCustom/TextCustom";
//Assets
import ImgHelixPromo from "assets/banners/helix-promo.png";
import ImgHelixPromoMobile from "assets/banners/helix-promo-mobile.png";
import ImgProdutos from "assets/images/anoTodo/produtos.png";
import ShellIcon from "assets/images/box-shell.svg";

export default function OilPromotions() {
  const { width } = useWindowSize();

  function handleDownload(href: string) {
    const link = document.createElement("a");
    link.href = href;
    link.download = href;
    link.click();
  }

  return (
    <Container>
      <HeaderPromo>
        <BannerWrapper
          bgImg={width < 1024 ? ImgHelixPromoMobile : ImgHelixPromo}
        >
          {/* <img src={width < 1024 ? ImgHelixPromoMobile : ImgHelixPromo} draggable="false" /> */}
          <a
            // href={"https://promocaoshellhelix.com.br/"}
            href={"#"}
            // target={"_blank"}
            // rel={"noopener noreferer"}
            className={"cta"}
          >
            {/* Participar Agora */}
            Promoção encerrada
          </a>
        </BannerWrapper>
        <HeaderBlockWrapper>
          <HeaderBlock bgColor={theme.color.red}>
            <h2 className="spaced">promoção</h2>
            <TextCustom>
              você pede <b>mais</b>
            </TextCustom>
            <TextCustom>seu carro pede</TextCustom>
            <Text as={"strong"}>shell helix</Text>
          </HeaderBlock>
          <HeaderBlock bgColor={theme.color.purple}>
            <Box
              d={"flex"}
              alignItems={"center"}
              w={"100%"}
              boxSizing={"border-box"}
            >
              <Box
                w={"100%"}
                h={"1px"}
                backgroundColor={theme.color.lightGray200}
                ml={"1.125rem"}
                mr={"0.5rem"}
              />
              <h2 className="varY">concorra a</h2>
              <Box
                w={"100%"}
                h={"1px"}
                backgroundColor={theme.color.lightGray200}
                mr={"1.125rem"}
                ml={"0.5rem"}
              />
            </Box>
            <Box d={"flex"}>
              <Box
                d={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                mr={"0.75rem"}
              >
                <TextCustom
                  fontSize={["1.125rem", "1.125rem", "1.125rem", "1rem"]}
                >
                  vouchers de R$50
                </TextCustom>
                <TextCustom
                  fontSize={["1.125rem", "1.125rem", "1.125rem", "1rem"]}
                  mr={"2px"}
                >
                  no app <span className="varY">shell box</span>
                </TextCustom>
              </Box>
              <img src={ShellIcon} alt="" />
            </Box>
            <Box
              d={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              my={["4px", "4px", "4px", "unset"]}
            >
              <TextCustom className="varY md">são</TextCustom>
              <Text
                as={"span"}
                ml={"0.25rem"}
                mr={"0.125rem"}
                fontFamily={theme.Shell.Book}
                className="varY"
                fontSize={["1.375rem", "1.375rem", "1.375rem", "1.25rem"]}
                lineHeight={"100%"}
              >
                R$
              </Text>
              <TextCustom
                as="strong"
                fontSize={["4rem", "4rem", "4rem", "3.75rem"]}
                letterSpacing={"-2pt"}
                pb={"0.75rem"}
                className="xl"
              >
                1000
              </TextCustom>
            </Box>
            <TextCustom
              fontSize={["1.25rem", "1.25rem", "1.25rem", "1.125rem"]}
              letterSpacing={"0.25pt"}
            >
              em vale combustível
            </TextCustom>
            <TextCustom
              fontSize={["1.188rem", "1.188rem", "1.188rem", "1.063rem"]}
              className={"varY"}
            >
              por dia de promoção
            </TextCustom>
          </HeaderBlock>
          <HeaderBlock
            position="left"
            bgColor={theme.color.yellow}
            bgImage={ImgProdutos}
          >
            <Box
              display={"flex"}
              flexDir={"column"}
              ml={"1.25rem"}
              h={["12rem", "12rem", "12rem", "9.5rem"]}
            >
              <TextCustom
                fontSize={["1.75rem", "1.75rem", "1.75rem", "1.375rem"]}
                color={theme.color.red}
              >
                <span className="plus">+</span>centenas
              </TextCustom>
              <TextCustom
                color={theme.color.text}
                fontSize={["1.375rem", "1.375rem", "1.375rem", "1.125rem"]}
                lineHeight={"100%"}
              >
                de prêmios
              </TextCustom>
              <TextCustom
                color={theme.color.text}
                fontSize={["1.375rem", "1.375rem", "1.375rem", "1.125rem"]}
                lineHeight={"100%"}
              >
                na hora
              </TextCustom>
            </Box>
          </HeaderBlock>
        </HeaderBlockWrapper>
      </HeaderPromo>
      <Content>
        <h2>veja os {width < 1024 ? <br /> : null} regulamentos</h2>
        <Box
          display={"flex"}
          flexDir={["column", "column", "row"]}
          justifyContent={"center"}
          alignItems={"center"}
          w={"100%"}
          maxW={"35rem"}
        >
          <RedButtons
            onClick={() =>
              handleDownload(
                "https://t3086814.p.clickup-attachments.com/t3086814/d2bbbe8c-331e-480a-9a39-21ffd92bd3ca/Regulamento%20Shell%20-%20VB.pdf"
              )
            }
          >
            prêmio instantâneo
          </RedButtons>
          <RedButtons
            onClick={() =>
              handleDownload(
                "https://t3086814.p.clickup-attachments.com/t3086814/9930b708-6358-4813-a998-664ff78a036b/Regulamento%20Shell%20-%20ST.pdf"
              )
            }
          >
            número da sorte
          </RedButtons>
        </Box>
      </Content>
    </Container>
  );
}

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.875rem;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-top: 5.5rem;
  }
`;

const HeaderPromo = styled.header`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    max-height: 31.75rem;
  }
`;

const HeaderBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    flex: 1;
    max-width: 26.25rem;
  }
  @media (min-width: 1600px) {
    max-width: 30rem;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 13rem;
  position: relative;
  background: url(${(props) => props.bgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  /* img {
    display: flex;
    width: 100%;
  } */

  a.cta {
    text-decoration: none;
    color: #fff;
    background-color: ${(props) => props.theme.color.red};
    font-family: ${(props) => props.theme.FuturaFontBold};
    font-size: 1rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    padding: 1.063rem;
    white-space: nowrap;

    position: absolute;
    left: 50%;
    bottom: 1.5rem;

    transform: translateX(-50%);
    box-sizing: border-box;
    transition: 0.2s;
    cursor: default;

    /* &:hover, &:focus {
      background-color: #fff;
      color: ${(props) => props.theme.color.red};
    } */
  }

  @media (min-width: 568px) {
    height: 16rem;
  }
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    height: 18rem;
  }
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    height: 31.75rem;
    flex: 3;

    a.cta {
      padding: 1.5rem 2.75rem;
      font-size: 1.25rem;
      line-height: 100%;
      bottom: 3rem;
    }
  }
`;

const Content = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  padding: 1.563rem 0;

  h2 {
    margin: 0 auto 1.5rem;
    font-family: ${(props) => props.theme.FuturaFontBold};
    font-size: 1.25rem;
    line-height: 110%;
    color: ${(props) => props.theme.color.text};
    text-align: center;
    text-transform: uppercase;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    h2 {
      font-size: 2rem;
      margin-bottom: 2.375rem;
    }
    padding: 2.5rem 0 2rem;
  }
`;

const RedButtons = styled.a`
  border: 2px solid ${(props) => props.theme.color.red};
  color: ${(props) => props.theme.color.red};
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${(props) => props.theme.FuturaFontBold};
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: center;
  width: 100%;
  max-width: 15.625rem;

  margin: 0 auto 1rem;
  padding: 1rem;
  transition: 0.2s;
  box-sizing: border-box;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.color.red};
    color: ${(props) => props.theme.color.white};
  }
`;
