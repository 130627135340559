import { thunk, action, Action, Thunk } from "easy-peasy";
import { GetMe } from "../services/user";
import { get } from "lodash";

export interface UserModel {
  isLoading: boolean;
  error: string;
  user: any;
  getMe: Thunk<UserModel, void>;
  setModel: Action<UserModel, Object>;
  setLoading: Action<UserModel, string>;
  setError: Action<UserModel, string>;
}

const user: UserModel = {
  isLoading: false,
  error: "",
  user: {},
  getMe: thunk(async (action: any, payload: any) => {
    try {
      const response = await GetMe();
      action.setModel(response.data);
    } catch (error) {
      action.setError({ message: "Error" });
    }
  }),
  setModel: action((state: any, payload: any) => {
    const nameArray = payload.data.name.split(" ");
    state.user = payload.data;
    state.user.firstName = nameArray[0];
  }),
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { error: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.error = message;
  }),
};

export default user;
