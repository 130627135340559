import React from "react";
import styled from "styled-components";
import BannerHub from "../BannerHub";
import { useActions, useStore } from "configureStore";
import useWindowSize from "hooks/useWIndowSize";
import { Spinner } from "@chakra-ui/core";
import { theme } from "theme";
import Carousel from "react-multi-carousel";
import { CarouselWebdoorSlide } from "../CarouselBanner/CarouselSlide";

export function DoubleBannerHub() {
  const { width } = useWindowSize();
  const isLoading = useStore((state) => state.hub.isCarouselLoading)
  const doubleBannerList = useStore((state) => state.hub.webdoorDoubleBanner)

  if (isLoading) {
    return (
      <Spinner
        marginLeft={["45vw", "45vw", "45vw", "50vw"]}
        size="xl"
        color={theme.color.orange}
        emptyColor={theme.color.lightGray200}
        thickness="6px"
        marginTop={["3rem", "3rem", "3rem", "6rem"]}
      />
    )
  }

  return (
    <DoubleBannerContainer>
      <Carousel
        responsive={{
          desktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 1,
            slidesToSlide: 1
          },
          mobile: {
            breakpoint: { max: 1199, min: 0 },
            items: 1,
            slidesToSlide: 1
          }
        }}
        arrows={false}
        minimumTouchDrag={30}
        showDots={Boolean(doubleBannerList.length > 1)}
        renderDotsOutside
      >
        {doubleBannerList.length > 0 && doubleBannerList?.map((slide) => (
          <DoubleBannerItem
            key={slide?.firstBanner?.url}
          >
            {slide?.firstBanner?.imagem_responsiva && slide?.firstBanner?.imagem_desktop && (
              <CarouselWebdoorSlide
                href={slide?.firstBanner?.url || "#"}
                isNewTab={slide?.firstBanner?.nova_janela}
              >
                <img
                  src={width <= 1024 ? slide?.firstBanner?.imagem_responsiva : slide?.firstBanner?.imagem_desktop}
                  alt={slide?.firstBanner?.descricao || ""}
                  draggable="false"
                />
              </CarouselWebdoorSlide>
            )}
            {slide?.secondBanner?.imagem_responsiva && slide?.secondBanner?.imagem_desktop && (
            <CarouselWebdoorSlide
              href={slide?.secondBanner?.url || "#"}
              isNewTab={slide?.secondBanner?.nova_janela}
            >
              <img
                src={width <= 1024 ? slide?.secondBanner?.imagem_responsiva : slide?.secondBanner?.imagem_desktop}
                alt={slide?.secondBanner?.descricao || ""}
                draggable="false"
              />
            </CarouselWebdoorSlide>
            )}
          </DoubleBannerItem>
        ))}
      </Carousel>
    </DoubleBannerContainer>
  );
}


const DoubleBannerContainer = styled.section`
  /* display: flex; */
  width: 100%;
  margin-top: 2.75rem;
  padding-bottom: 1.5rem;
  position: relative;

  li.react-multi-carousel-dot {
    button {
      background-color: #e2d7e2;
      border: 1px solid #000;
      width: 10px;
      height: 10px;
      &:focus {
        outline: auto;
      }
    }
  }

  li.react-multi-carousel-dot--active {
    button {
      background-color: ${(props) => props.theme.color.yellow};
    }
  }
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    margin-top: 5.5rem;
    padding-bottom: 2.5rem;

    li.react-multi-carousel-dot {
      button {
        width: 14px;
        height: 14px;
        margin: 0 3px;
      }
    }
  }
`;

const DoubleBannerItem = styled.article`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 10px;

  width: 100%;
  height: max-content;

  div.slide-item {
    width: 100%;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  @media (min-width: ${props => props.theme.queries.medium}) {
    grid-template-rows: unset;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    
    div.slide-item {
      img {
        width: 100%;
      }
    }
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    div.slide-item {
      img {
        object-fit: cover;
      }
    }
  }
`