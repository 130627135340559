import React, { useState } from 'react';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWIndowSize';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CustomButtonGroup } from './CustomArrows';

import { AiFillWarning } from 'react-icons/ai';

interface ImageListProps {
  imageList: ImagePromotions[];
}

interface ImagePromotions {
  url: string;
  alt: string;
  href?: string;
}

function FinishedPromotionsCarousel({ imageList }: ImageListProps) {
  const { width } = useWindowSize();
  const [currentSlide, setCurrenSlide] = useState(0);

  function handleNextSlide() {
    setCurrenSlide(currentSlide + 1)
  }
  function handlePreviousSlide() {
    setCurrenSlide(currentSlide - 1)
  }

  const responsive = {
    mobile: {
      breakpoint: { max: 1279, min: 0 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 2,
      slidesToSlide: 1,
    },
  }

  return (
    <Container id="main">
      {/* {width < 1024 && <Title>campanhas passadas</Title>} */}
      <Content>
        {/* {width >= 1024 && <Title>campanhas passadas</Title>} */}
        <CarouselPromotionsCustom
          responsive={responsive}
          arrows={false}
          keyBoardControl
          containerClass={"promoEnd-container"}
          itemClass={"promoEnd-item"}
          customButtonGroup={
            <CustomButtonGroup
              next={handleNextSlide}
              previous={handlePreviousSlide}
              carouselState={currentSlide}
            />
          }
        >
          {
            imageList.map((image) => (
              <Card key={image.alt}>
                {/* <a
                  href={
                    image.alt.toLocaleLowerCase().includes("curta a vida")
                      ? "https://promo.shell.com.br/junte-troque"
                      : image.href
                  }
                  aria-label="Abre em uma nova guia"
                  target='_blank'
                  rel='noopener noreferrer'
                > */}
                  <img
                    src={image.url}
                    alt={image.alt || ""}
                    draggable="false"
                  />
                {/* </a> */}
                <div>
                  <AiFillWarning size={18} color={`#FFF`} />
                  <span>campanha encerrada</span>
                </div>
              </Card>
            ))
          }
        </CarouselPromotionsCustom>

      </Content>
    </Container>
  )
}

export default FinishedPromotionsCarousel;

const Container = styled.div`
  width: 100%;
  /* margin: 2rem 0; */
  margin: 0 0 2rem 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 40rem;
  background: #FFF;

  margin: 0 auto;

  @media (min-width: 1280px) {
    max-width: 93%;
    margin-top: 3rem;
  }

  @media (min-width: 1441px) {
    max-width: 1444px;
  }
`;

const Title = styled.h1`
  font-family: "Futura LT Bold";
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  width: 80%;

  margin: 2rem auto 1rem auto;
  color: ${props => props.theme.color.text};

  @media(max-width: 768px){
    font-size: 1.2rem;
  }
  @media(max-width: 320px){
    font-size: 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
  padding: 0 2rem;

  position: relative;

  @media (min-width: 1280px) {
    padding: 0 0.75rem;
  }

  a {
    display: flex;
    margin: 0;
    padding: 0;
    height: inherit;
  }

  img {
    display: block;
    width: 100%;
    height: 385px;
    /* filter: brightness(0.65); */
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0;
    flex: 1;

    background: #3A3A3A;

    span {
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #FFF;
      margin: 0 0.5rem;
    }
  }

  @media (max-width: 440px) {
    img {
      height: 200px;
    }
  }

`;

const CarouselPromotionsCustom = styled(Carousel)`
  width: 100%;
  max-height: 26rem;

  margin: 0 auto;

  @media (min-width: 1280px) {
    margin: 1rem auto
  }
`;