import ForgotPassword from "modules/common/Pages/forgotPassword";
import React from "react";
import styled from "styled-components"

export default function EsqueceuSenhaGp() {
  return (
    <Container>
      <ForgotPassword />  
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  width: 90%;
  margin: 1rem auto 0;

  button {
    border-radius: 12px;
    background-color: ${props => props.theme.color.red};
    
    &:hover {
      background-color: ${props => props.theme.color.red};
    }
  }
`