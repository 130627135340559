import { thunk, action, Action, Thunk } from "easy-peasy";
import { GetRules } from "../services/rule";
import { get } from "lodash";
import { getSlug } from "../utils/helper";

export interface RuleModel {
  isLoading: boolean;
  messageError: string;
  rules: any;

  getRules: Thunk<RuleModel, void>;

  setLoading: Action<RuleModel, string>;
  setError: Action<RuleModel, string>;
  setRules: Action<RuleModel, any>;
}

const rule: RuleModel = {
  // attributes
  isLoading: false,
  messageError: "",
  rules: {},

  // thunks
  getRules: thunk(async (action: any, payload: any) => {
    action.setLoading({ loading: true });
    try {
      const slug = getSlug();
      let res: any;
      switch (slug.url) {
        case "/junte-troque":
          if (
            window.location.host.includes("localhost") ||
            window.location.host.includes("promoshell-dev") ||
            window.location.host.includes("promoshell-qa")
          ) {
            res = await GetRules(101);
          } else {
            res = await GetRules(134);
          }
          if (window.location.pathname.includes("gp-sp-2023")) {
            if (
              window.location.host.includes("localhost") ||
              window.location.host.includes("promoshell")
            ) {
              res = await GetRules(134)
            } else {
              res = await GetRules(167)
            }
          }
          break;
        default:
          res = await GetRules(134);
          break;
      }
      action.setRules(res.data);
      action.setLoading({ loading: false });
    } catch (error) {
      // const objError = Object.assign({}, error);
      action.setError({
        message: "Não foi possivel obter os dados, tente novamente mais tarde!",
      });
      action.setLoading({ loading: false });
    }
  }),

  // actions
  setLoading: action((state: { isLoading: boolean }, payload: any) => {
    const loading = get(payload, "loading", false);
    state.isLoading = loading;
  }),
  setError: action((state: { messageError: string }, payload: any) => {
    const message = get(payload, "message", false);
    state.messageError = message;
  }),
  setRules: action((state: any, payload: any) => {
    state.rules = payload;
  }),
};

export default rule;
