import React from "react";
import {
  Container,
  ContainerTickets,
  LabelTickets,
  DescriptionTickets,
  Bold,
  ContainerSteps,
  WrapperSteps,
} from "./styles";
import { RedeemTickets } from "modules/promoGrandeGp/components/Home/RedeemTickets";
import { RedeemTicketsCardsSection } from "modules/promoGrandeGp/components/ResgateDeIngressos/RedeemTicketsCardsSection";

export default function ResgateDeIngressosGp() {
  return (
    <Container>
      <ContainerTickets>
        <LabelTickets>Fique atento à data do próximo lote!</LabelTickets>
        <DescriptionTickets>
          O próximo lote de resgate estará disponível no dia
          <Bold>11/10/2023 às 12h.</Bold>
        </DescriptionTickets>
      </ContainerTickets>
      <ContainerSteps>
        <WrapperSteps>
          <RedeemTicketsCardsSection />
          <RedeemTickets />
        </WrapperSteps>
      </ContainerSteps>
    </Container>
  );
}
