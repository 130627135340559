
import React, { useEffect } from "react"
import {
  ContainerIcon,
  CustomAccordionItem,
  CustomAcordion,
  DuvidasContainer,
  DuvidasWrapper,
  MyAccordionBox,
  MyAccordionHeader,
  MyAccordionIcon,
  MyAccordionPanel
} from "./styles"
import { useActions, useStore } from "configureStore";
import { Box, Spinner } from "@chakra-ui/core";
import { theme } from "theme";

export default function DuvidasGp() {
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const isShellbox = window.location.search.includes("shellbox");
  const getFAQ = useActions((action) => action.hub.getFaq);
  const faq = useStore((state) => state.hub.faq);
  const isLoading = useStore((state) => state.hub.isFaqLoading);

  useEffect(() => {
    if (window.location.host.includes("promo.shell.com.br")) {
      getFAQ(167);
    } else {
      getFAQ(134);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DuvidasWrapper>
      <DuvidasContainer>
        {isLoading ? (
          <Box margin={"1rem auto"}>
            <Spinner
              size="xl"
              thickness="6px"
              color={theme.color.red}
            />
          </Box>
        ) : (
          faq?.map((faqItem) => (
            <CustomAcordion
              defaultIndex={0}
              width={"100%"}
              allowMultiple
              key={faqItem.pergunta}
            >
              <CustomAccordionItem marginBottom={"30px"} height="100%">
                <MyAccordionHeader>
                  <MyAccordionBox flex="1" textAlign="left">
                    {faqItem?.pergunta}
                  </MyAccordionBox>
                  <ContainerIcon className={"btn-chevron"}>
                    <MyAccordionIcon />
                  </ContainerIcon>
                </MyAccordionHeader>
                <MyAccordionPanel pb={4}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: faqItem?.resposta,
                    }}
                  />
                </MyAccordionPanel>
              </CustomAccordionItem>
            </CustomAcordion>
          ))
        )}
      </DuvidasContainer>
    </DuvidasWrapper>
  )
}
