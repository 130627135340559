import React from 'react'
import styled from "styled-components"
import { GanhadoresTable as TableContainer } from '../styles'

interface TableData {
  nome: string
  CPF: string
  numeroDaSorte: string
}

export function GanhadoresTable({ tableData }: { tableData: TableData[] }) {
  const dt = tableData.filter((data) => data.numeroDaSorte.includes('xxx'))
  console.log(dt.length)

  return (
    <TableScrollerWrapper>
      <TableContainer>
        <thead>
          <tr>
            <th>Número da sorte</th>
            <th>Nome do ganhador</th>
            <th>CPF</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 1 && tableData.map(({ CPF, nome, numeroDaSorte }) => (
            <tr tabIndex={0}>
              <td>{numeroDaSorte}</td>
              <td>{nome}</td>
              <td>{CPF}</td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </TableScrollerWrapper>
  )
}

const TableScrollerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  max-width: 868px;
  overflow-x: auto;

  @media (min-width: ${props => props.theme.queries.lg}) {
  }
`