import Register from "modules/common/Pages/register"
import React from "react"
import styled from "styled-components"

export default function CadastroGp() {
  return (
    <CadastroContainer>
      <CadastroContent>
        <Register />
      </CadastroContent>
    </CadastroContainer>
  )
}

const CadastroContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CadastroContent = styled.article`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1260px;
  margin: 1.5rem auto;

  button {
    border-radius: 12px;
    border-color: ${props => props.theme.color.red} !important;
  }

  a {
    color: ${props => props.theme.color.red};
  }
`