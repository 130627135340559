import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import useWindowSize from "hooks/useWIndowSize";
import {
  ImageCarousel,
  ImageContainer,
} from "modules/common/ConhecaProdutoStyles";
import { Box, Spinner, useDisclosure } from "@chakra-ui/core";
import { ProductCardLabel } from "./ProductCardLabel";
import { useActions, useStore } from "configureStore";
import { GetUrlShop } from "services/shell";
import { ProductsProps } from "models/anoTodo";
import { pushDataLayer } from "utils/pushDataLayer";
import {
  BoxPriceParagraph,
  BoxQuero,
  ButtonQueroProducts,
  ContainerCategory,
  ContainerSection,
  ProductCard,
} from "./SectionProductCardsStyles";

import IconDownload from "assets/images/anoTodo/icons/download-icon.svg";
import ProductsCategory from "./ProductsCategory";
import { useSSO } from "hooks/useSSO";

type ModalContentProps = {
  title: string;
  description: string;
  status?: number;
  shellCost: number;
  imgArray: Array<any>;
  especial?: boolean;
  index?: number;
};

type ProductSSO = {
  productId: number;
  isRedirectReady: boolean;
};

export default function SectionProductCards({ isAuth }) {
  const [modalContent, setModalContent] = useState<ModalContentProps>();
  const [cardList, setCardList] = useState<ProductsProps[]>();
  const [gridLines, setGridLines] = useState<number>(1);
  // const [item, setItem] = useState(null);
  // const [productSSO, setProductSSO] = useState<ProductSSO>();
  const { width } = useWindowSize();
  const products = useStore((state) => state.anoTodo.products);
  const isLoading = useStore((state) => state.anoTodo.isProductLoading);
  const setActive = useActions((action) => action.loading.setActive);
  const isShellbox = window.location.search.includes("shellbox");
  const [mouseXpos, setMouseXpos] = useState<number>(null);
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const sessionStorageKey = "productSSO"
  const formElementId = "formBox"
  const { getStorageProductSSO, handleRedeemSelectedProduct, responseSSO } = useSSO()

  const imgResponsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
    },
  };

  // function handleOpenModalContent(
  //   title: string,
  //   description: string,
  //   shells: number,
  //   imgLarge: {
  //     arquivo: string;
  //     descricao: string;
  //   }[]
  // ) {
  //   setModalContent({
  //     title: title,
  //     description: description,
  //     shellCost: shells,
  //     imgArray: imgLarge,
  //   });
  //   onOpen();
  // }

  function handleWebviewExternalLink(url: string) {
    if (isShellbox) {
      try {
        // @ts-ignore
        WebViewJavascriptInterface.openExternalLink(url);
      } catch (_) {
        window.open(url, "_blank", "noopener noreferer");
        window.focus();
      }
      setActive(true);
    }
  }

  function handleSlideClick(card: ProductsProps, mousePos: number) {
    let xPos = mousePos;
    if (xPos - mouseXpos === 0) {
      // handleRedeemSelectedProduct(card?.productId);
      handleRedeemSelectedProduct(card?.productId, sessionStorageKey, formElementId);
    }
  }

  useEffect(() => {
    getStorageProductSSO(sessionStorageKey); // Pega valor na sessionStorage e armazena no estado
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setCardList(products);
  // }, [products]);

  // useEffect(() => {
  //   if (width < 1024) {
  //     setGridLines(Math.ceil(cardList?.length / 2));
  //   } else {
  //     setGridLines(Math.ceil(cardList?.length / 4));
  //   }
  // }, [cardList, width]);

  useEffect(() => {
    if (cardList && cardList.length > 0) {
      let itemsProducts = [];

      for (let i = 0; i < cardList.length; i++) {
        const card = cardList[i];
        itemsProducts.push({
          name: card.name, // Nome do produto
          id: card.productId, // Id do produto
          price: card.price, // Preço do produto
          brand: "Shell", // Marca do produto
          category: card.tag?.[0]?.titulo, // Categoria do produto
          variant: "", // Variação do produto
          list: "Home", //Nome da lista
          position: i, // Posição do produto na lista
        });
      }

      pushDataLayer({ ecommerce: null });

      pushDataLayer({
        event: "productImpression",
        ecommerce: {
          currencyCode: "BRL",
          impressions: itemsProducts,
        },
      });
    }
  }, [cardList]);

  return (
    <ContainerCategory>
      <ProductsCategory
        handleRedeemSelectedProduct={handleRedeemSelectedProduct}
        sessionStorageKey={sessionStorageKey}
        formElementId={formElementId}
      />
      <ContainerSection>
        {cardList?.map((card, index) => (
          <ProductCard id={`product-${card?.productId}`} key={card.name}>
            {isLoading ? (
              <Box
                w={"100%"}
                d={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="yellow.500"
                  size="xl"
                />
              </Box>
            ) : (
              <>
                <ProductCardLabel
                  title={card?.tag[0]?.titulo}
                  bgColor={card?.tag[0]?.cor_fundo}
                  titleColor={card?.tag[0]?.cor_titulo}
                />

                <ImageCarousel
                  responsive={imgResponsive}
                  showDots
                  infinite
                  arrows={false}
                  renderDotsOutside
                  isAnoTodo={true}
                >
                  {card.imgSmall.map(({ arquivo, descricao }) => (
                    <ImageContainer
                      shellCost={card.shellCost}
                      status={null}
                      index={index}
                      key={card.productId}
                      isAnoTodo
                    >
                      <img
                        src={arquivo}
                        alt={`Imagens do Produto ${card.name}`}
                        draggable="false"
                        onClick={(e) => {
                          handleSlideClick(card, e.pageX);
                        }}
                        onMouseDown={(e) => {
                          setMouseXpos(e.pageX);
                        }}
                        onMouseUp={(e) => {
                          handleSlideClick(card, e.pageX);
                        }}
                      />
                    </ImageContainer>
                  ))}
                </ImageCarousel>

                <Box
                  d={"flex"}
                  flexDir={"column"}
                  w={"95%"}
                  m={"auto"}
                  position={"relative"}
                >
                  <h2>{card.name}</h2>
                  {width >= 1024 && (
                    <Box>
                      <p>{card.description}</p>
                      <Box>
                        {Boolean(card.manual) && (
                          <a
                            href={card.manual}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Ao clicar no link será aberto uma nova aba com um arquivo no formato PDF."
                            download
                          >
                            <img
                              className="dlicon"
                              src={IconDownload}
                              alt=""
                              draggable="false"
                            />
                            Baixar manual
                          </a>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <BoxQuero>
                  <BoxPriceParagraph indisponivel={card.indisponivel}>
                    {card.shellCost}{" "}
                    <b>{card.shellCost === 1 ? "concha" : "conchas"}</b>{" "}
                    <span className="red">+</span> <br />{" "}
                    <strong>{card.priceShells}</strong>
                    {card.price && (
                      <Box mt={"4px"} mb={["6px", "6px", "8px"]}>
                        {" "}
                        <span>ou {card.price}</span>
                      </Box>
                    )}
                  </BoxPriceParagraph>
                  {card.indisponivel === false &&
                    (buttonClickCount < 1 ? (
                      <ButtonQueroProducts className={"disabled"}>
                        <Spinner />
                      </ButtonQueroProducts>
                    ) : (
                      <ButtonQueroProducts
                        onClick={() =>
                          handleRedeemSelectedProduct(card.productId, sessionStorageKey, formElementId)
                        }
                      >
                        quero!
                      </ButtonQueroProducts>
                    ))}
                </BoxQuero>
              </>
            )}
          </ProductCard>
        ))}
      </ContainerSection>
      {/* <CustomProductModal
        isOpen={isOpen}
        onClose={onClose}
        modalContent={modalContent}
      /> */}

      {/* SSO */}
      <form
        action={`https://${responseSSO?.data?.redirectURL || "shelljunte-troque.shop"
          }?acesso=1&webview=${window.location.search.includes("shellbox") ? 1 : 0
          }&produto=${responseSSO?.productId}`}
        style={{ display: "none" }}
        method="post"
        target="_self"
        id={formElementId}
      >
        <input
          type="hidden"
          name="token"
          id="token"
          value={`Bearer ${responseSSO?.data?.token}`}
        ></input>
        <input
          type="hidden"
          name="webview"
          id="webview"
          value={window.location.search.includes("shellbox") ? 1 : 0}
        ></input>
        <input type="hidden" name="acesso" value={1}></input>
        <input
          type="hidden"
          name="produto"
          id="produto"
          value={responseSSO?.productId || ""}
        ></input>
      </form>
    </ContainerCategory>
  );
}
