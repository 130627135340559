import React from "react"
import {
  SorteioCardIcon,
  SorteioSectionCard,
  SorteioSectionContainer,
  SorteioSectionTitle,
  WrapperSectionCardMobile
} from "./styles";
import { getSlug } from "utils/helper";

import IconMobile from 'assets/icons/onda/mobile.svg'
import IconBomba from 'assets/images/grandeGp/bomba.svg'
import IconClover from 'assets/images/grandeGp/clover.png'
import IconFlag from 'assets/images/grandeGp/flag.svg'
import { RenderCarousel } from "./CarouselRenderer";
import useScrollToModalLogin from "hooks/useScrollToModalLogin";
import { useStore } from "configureStore";
import { useRouter } from "hooks/useRouter";
import useWindowSize from "hooks/useWIndowSize";

export function SorteioSection() {
  const isAuth = useStore((state) => state.auth.isAuthenticated)
  const slug = getSlug().url;
  const router = useRouter()
  const scrollToModal = useScrollToModalLogin()
  const { width } = useWindowSize();
  const isDesktop = width >= 1280;
  const isMobile375 = width <= 375;


  function handleCardItemClick() {
    if (isAuth) {
      router.push(`${slug}/gp-sp-2023/numeros-da-sorte`)
    }
    scrollToModal()
  }

  return (
    <SorteioSectionContainer>
      <SorteioSectionTitle>Participe do sorteio com Shell Box e Shell V-Power</SorteioSectionTitle>


    {
      isMobile375 ? (
      <WrapperSectionCardMobile>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconMobile} alt="Ícone de um celular" />
          </SorteioCardIcon>
          <span>passo 1</span>
          <strong>Baixe o aplicativo Shell Box</strong>
          <p>
            Disponível para iOS e Android.
            <br />
            <br />
            Não se esqueça de dar o aceite para participar das promoções.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
          </SorteioCardIcon>
          <span>passo 2</span>
          <strong>
            Abasteça com <br /> Shell V-Power
          </strong>
          {isDesktop ? (
            <p style={{ width: isDesktop ? `90%` : "100%" }}>
              Abasteça a partir de R$100 com Shell V-Power Gasolina, Shell
              V-Power Etanol e/ou Shell V-Power Racing pelo aplicativo Shell
              Box.
            </p>
          ) : (
            <p>
              Abasteça a partir de R$100 com <span>Shell V-Power</span>{" "}
              Gasolina,
              <span>Shell V-Power</span> Etanol e/ou Shell V-Power Racing pelo
              aplicativo <span>Shell Box</span>.
            </p>
          )}
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconClover} alt="Ícone de trevo de quatro folhas" />
          </SorteioCardIcon>
          <span>passo 3</span>
          <strong>
            Concorra a ingressos
          </strong>
          <p>
            Ganhe números da sorte para concorrer a 1 par de ingressos para o Grande Prêmio de São Paulo.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconFlag} alt="Ícone de bandeira quadriculada" />
          </SorteioCardIcon>
          <span>passo 4</span>
          <strong>
            Cruze os dedos e acompanhe os sorteios!
          </strong>
          <p>
            Confira seus números da sorte no <button aria-label="menu" onClick={handleCardItemClick}>menu</button>{" "}
            e fique atento as datas do sorteio. Boa sorte!
          </p>
        </SorteioSectionCard>
      </WrapperSectionCardMobile>
      ) : (
         <RenderCarousel>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconMobile} alt="Ícone de um celular" />
          </SorteioCardIcon>
          <span>passo 1</span>
          <strong>Baixe o aplicativo Shell Box</strong>
          <p>
            Disponível para iOS e Android.
            <br />
            <br />
            Não se esqueça de dar o aceite para participar das promoções.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
          </SorteioCardIcon>
          <span>passo 2</span>
          <strong>
            Abasteça com <br /> Shell V-Power
          </strong>
          {isDesktop ? (
            <p style={{ width: isDesktop ? `90%` : "100%" }}>
              Abasteça a partir de R$100 com Shell V-Power Gasolina, Shell
              V-Power Etanol e/ou Shell V-Power Racing pelo aplicativo Shell
              Box.
            </p>
          ) : (
            <p>
              Abasteça a partir de R$100 com <span>Shell V-Power</span>{" "}
              Gasolina,
              <span>Shell V-Power</span> Etanol e/ou Shell V-Power Racing pelo
              aplicativo <span>Shell Box</span>.
            </p>
          )}
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconClover} alt="Ícone de trevo de quatro folhas" />
          </SorteioCardIcon>
          <span>passo 3</span>
          <strong>
            Concorra a ingressos
          </strong>
          <p>
            Ganhe números da sorte para concorrer a 1 par de ingressos para o Grande Prêmio de São Paulo.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconFlag} alt="Ícone de bandeira quadriculada" />
          </SorteioCardIcon>
          <span>passo 4</span>
          <strong>
            Cruze os dedos e acompanhe os sorteios!
          </strong>
          <p>
            Confira seus números da sorte no <button aria-label="menu" onClick={handleCardItemClick}>menu</button>{" "}
            e fique atento as datas do sorteio. Boa sorte!
          </p>
        </SorteioSectionCard>
      </RenderCarousel>
      )
    }
      <span>* Confira o regulamento da promoção e saiba mais.</span>
    </SorteioSectionContainer>
  )
}