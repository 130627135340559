import React from "react";
import {
  FooterContainer,
  FooterCopyright,
  FooterInfoWrapper,
  FooterLinkWrapper,
  FooterNotice
} from "./styles";

import IconConchaShell from "assets/icons/concha-shell.svg";
import { FooterSmartLink } from "./FooterSmartLink";
import { NavLink } from "react-router-dom";

export function Footer() {

  return (
    <FooterContainer>
      <FooterLinkWrapper>
        <FooterSmartLink
          href={`https://www.shell.com.br/termos-e-condicoes.html`}
          isExternal
        >
          Termos de Uso
        </FooterSmartLink>
        <FooterSmartLink
          href={`https://www.shell.com.br/politica-de-privacidade.html`}
          isExternal
        >
          Política de Privacidade
        </FooterSmartLink>
        <FooterSmartLink href={`/regulamento`}>
          Regulamento
        </FooterSmartLink>
        <FooterSmartLink href={`/duvidas`}>
          Dúvidas
        </FooterSmartLink>
      </FooterLinkWrapper>
      <FooterInfoWrapper>
        <FooterCopyright>
          <img src={IconConchaShell} alt="" />
          <span>Copyright 2023 - Todos os direitos reservados a Shell</span>
        </FooterCopyright>
        <FooterNotice>
          <p>
            Para participar dessa promoção é necessário baixar o aplicativo Shell Box,
            habilitar a opção de participação automática em promoções e abastecer a
            partir de R$100,00 com Shell V-Power Etanol, Shell V-Power Gasolina, e/ou
            Shell V-Power Racing pelo app Shell Box. Consulte o número e regulamento da
            promoção em <NavLink to="/">promo.shell.com.br</NavLink>. A marca Shell é licenciada à Raizen S/A.
          </p>
        </FooterNotice>
      </FooterInfoWrapper>
    </FooterContainer>
  )
}