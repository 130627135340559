import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import { Box, Icon, Button, Text } from "@chakra-ui/core";
import { Rifm } from "rifm";
import TextField, { Input } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import ReCAPTCHA from "react-google-recaptcha";
import {
  parseToDate,
  formatToDate,
  formatToCPF,
  formatToPhone,
  isDate,
} from "brazilian-values";
import { theme } from "theme";
import { getSlug } from "utils/helper";
import { useStore, useActions } from "configureStore";

import ContainerSide from "modules/common/Auth/ContainerSide";
import useWindowSize from "hooks/useWIndowSize";
import useShellbox from "hooks/useShellbox";
import { VscLinkExternal } from "react-icons/vsc";
import { useRouter } from "hooks/useRouter";
import { IconNewWindow } from "modules/common/IconNewWindow";

export default function Register() {
  const location = useLocation();
  const isNotRegister = !location.pathname?.includes("cadastro");
  const drawer = document.getElementById("drawer-update");
  const modal = document.getElementById("modal-update");
  const password = useRef(null);
  const isLoading = useStore((state) => state.auth.isAuthLoading);
  const authError = useStore((state) => state.auth.authError);
  const passwordError = useStore((state) => state.auth.passwordError);
  const updateError = useActions((state) => state.auth.updateAuthError);
  const registerError = useStore((state) => state.auth.registerError);
  const registerAuthError = useActions((state) => state.auth.registerAuthError);
  const updatePasswordError = useActions(
    (state) => state.auth.updatePasswordError
  );
  const signup = useActions((actions) => actions.auth.createUser);
  const update = useActions((actions) => actions.auth.updateUser);
  const updatePassword = useActions((actions) => actions.auth.changePassword);
  const user = useStore((state) => state.user.user);
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const authRecaptchaError = useStore((state) => state.auth.authRecaptchaError);
  const setUpdateAuthRecaptchaError = useActions(
    (action) => action.auth.updateAuthRecaptchaError
  );
  const loginError = useStore((state) => state.auth.loginError);
  const { width } = useWindowSize();
  const recaptchaRef = useRef(null);

  const [viewPassword, setViewPassword] = useState(false);
  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewNewConfirmPassword, setViewNewConfirmPassword] = useState(false);
  const isShellbox = useShellbox();
  const router = useRouter();
  const setActive = useActions((actions) => actions.loading.setActive);

  useEffect(() => {
    if (getSlug()?.url?.includes("junte-e-ganhe")) {
      document.title =
        "Promoshell | Benefícios de montão, só no Shell Box! Descontos em produtos e muita economia no abastecimento.";
    } else {
      document.title = "Promoshell | Cadastre-se";
    }
  }, []);

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        updateError("");
      }, 6000);
    }
    if (registerError) {
      setTimeout(() => {
        registerAuthError("");
      }, 6000);
    }
  }, [loginError, registerAuthError, registerError, updateError]);

  useEffect(() => {
    if (authRecaptchaError && recaptchaRef?.current) {
      recaptchaRef.current.reset();
      setUpdateAuthRecaptchaError("");
      formik.setFieldValue("reCaptcha", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRecaptchaError, setUpdateAuthRecaptchaError]);

  const parseDigits = (string) => (string?.match(/\d+/g) || []).join("");

  const formatCpf = (string) => {
    const digits = parseDigits(string && string).substr(0, 11);
    return formatToCPF(digits);
  };
  const formatPhone = (string) => {
    const digits = parseDigits(string && string).substr(0, 11);
    return formatToPhone(digits);
  };

  const formatDate = (string) => {
    const digits = parseDigits(string && string);
    const chars = digits.split("");
    return chars
      .reduce(
        (r, v, index) =>
          index === 2 || index === 4 ? `${r}/${v}` : `${r}${v}`,
        ""
      )
      .substr(0, 10);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      cpf: "",
      email: "",
      phone: "",
      birthday: "",
      password: "",
      reCaptcha: false,
      reCaptchaValue: "",
    },
    validate: (values) => {
      let errors = {} as any;

      if (!values.name) {
        errors.name = `O campo "Meu Nome" é obrigatório`;
      }
      if (!values.cpf && !isAuthenticated) {
        errors.cpf = `O campo "CPF" é obrigatório`;
      } else if (
        values?.cpf.replaceAll(".", "").replaceAll("-", "").length < 11 &&
        !isAuthenticated
      ) {
        errors.cpf = 'O campo "CPF" deve ter no mínimo 11 dígitos';
      }
      if (!values.phone) {
        errors.phone = `O campo "Celular" é obrigatório`;
      }
      let phone = values.phone.replace(/[^A-Z0-9]/gi, "");
      if (phone.length < 11) {
        errors.phone = `O número de telefone deve ter no mínimo 11 dígitos`;
      }
      if (!values.password && !isAuthenticated) {
        errors.password = `O campo "Senha" é obrigatório`;
      } else if (
        (!isAuthenticated &&
          !/^(?=.*?[a-zA-Z])(?=.*?[0-9]).*.{8,32}$/.test(values.password)) ||
        values.password?.length > 32
      ) {
        errors.password =
          "Sua senha deve ter entre 8 e 32 caracteres contendo letras e números.";
      }
      if (!values.birthday) {
        errors.birthday = `O campo "Data de Nascimento" é Obrigatório`;
      }
      if (values.birthday.length < 10) {
        errors.birthday = `O campo "Data de Nascimento" está inválido`;
      }
      if (!isDate(values.birthday)) {
        errors.birthday = `A data inserida está inválida`;
      }
      if (!values.email) {
        errors.email = `O campo "E-mail" é obrigatório`;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = `E-mail inserido é inválido`;
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      values.cpf = values.cpf
        .replace(".", "")
        .replace(".", "")
        .replace("-", "");
      values.phone = values.phone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "");
      values.email = values.email.toLowerCase();
      values.email = values.email.trim();
      if (isAuthenticated) {
        let jsonData = {
          name: values.name,
          emailAddress: values.email,
          phoneNumber: values.phone,
          birthDate: parseToDate(values.birthday),
        } as any;
        if (
          !window.location.host.includes("promoshell-dev") &&
          !window.location.host.includes("192") &&
          !window.location.host.includes("localhost")
        ) {
          if (values.reCaptcha) {
            jsonData.recaptcha = values.reCaptchaValue.toString();
            update(jsonData as any);
          }
        } else {
          update(jsonData as any);
        }
      } else {
        let jsonData = {
          name: values.name,
          documentNumber: values.cpf,
          emailAddress: values.email,
          phoneNumber: values.phone,
          birthDate: parseToDate(values.birthday),
          password: values.password,
        } as any;

        if (
          !window.location.host.includes("promoshell-dev") &&
          !window.location.host.includes("localhost") &&
          !window.location.host.includes("192")
        ) {
          if (values.reCaptcha) {
            jsonData.recaptcha = values.reCaptchaValue.toString();
            signup(jsonData as any);
          }
        } else {
          signup(jsonData as any);
        }
      }
      // window.scrollTo(0, 0);
      modal?.scrollTo(0, 0);
      drawer?.scrollTo(0, 0);
      setFieldValue("reCaptcha", false);
      recaptchaRef.current.reset();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (
      Object.keys(formik.errors)?.length > 0 &&
      Object.keys(formik.touched)?.length > 0
    ) {
      // window.scrollTo(0, 0);
      modal?.scrollTo(0, 0);
      drawer?.scrollTo(0, 0);
    }
  }, [drawer, formik.errors, formik.touched, modal]);

  useEffect(() => {
    const _element = document.getElementById(Object.keys(formik?.errors)[0]);
    // console.log(Object.keys(formik.errors));

    if (_element) {
      const timer = setTimeout(() => {
        _element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }, 500);

      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty]);

  useEffect(() => {
    if (user && user?.name?.length > 0) {
      formik.setValues({
        name: user.name,
        cpf: user.documentNumber,
        email: user.email,
        phone: user.phone,
        birthday: formatToDate(new Date(user.birthDate)),
        reCaptcha: false,
        password: "update",
      } as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (authError) {
      setTimeout(() => {
        updateError("");
      }, 5000);
    }
  }, [authError, updateError]);

  useEffect(() => {
    if (passwordError) {
      setTimeout(() => {
        updatePasswordError("");
      }, 5000);
    }
  }, [passwordError, updatePasswordError]);

  const formikPassword = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updatePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      } as any);
      setSubmitting(false);
      resetForm();
    },
    validate: (values) => {
      let errors = {} as any;
      if (values.newPassword === values.currentPassword) {
        errors.newPassword = "Nova senha deve ser diferente da senha atual!";
      }
      if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword =
          "Nova senha deve ser igual a senha de confirmação!";
      }
      return errors;
    },
  });

  function handleWebviewExternalLink(
    url: string,
    label: string,
    isNewTab: boolean
  ) {
    if (isShellbox) {
      return (
        <MyLink
          target={isNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
          onClick={() => {
            if (isNewTab) {
              try {
                // @ts-ignore
                WebViewJavascriptInterface.openExternalLink(url);
              } catch (_) {
                window.open(url, "_blank", "noopener");
                window.focus();
              }
              setActive(true);
            } else {
              router.push(url);
            }
          }}
        >
          {label} {isNewTab && <VscLinkExternal />}
        </MyLink>
      );
    } else {
      return (
        <MyLink
          aria-label={`${label} (abre em uma nova aba)`}
          href={url}
          target={isNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {label} {isNewTab && <IconNewWindow width="1rem" height="1rem" />}
        </MyLink>
      );
    }
  }
  const isAnoTodo = Boolean(window.location.pathname.includes("junte-troque"));
  const isGrandeGp = Boolean(window.location.pathname.includes("gp-sp-2023"));

  return (
    <ContainerLocal>
      <Text
        fontFamily="Arial"
        fontSize=".9rem"
        marginTop="0"
        marginBottom="1.8rem"
      >
        Ao realizar o meu cadastro eu aceito os{" "}
        {handleWebviewExternalLink(
          "https://www.shell.com.br/termos-e-condicoes.html",
          "Termos e Condições",
          true
        )}
        , a{" "}
        {handleWebviewExternalLink(
          "https://www.shell.com.br/politica-de-privacidade.html",
          "Política de Privacidade",
          true
        )}{" "}
        e o{" "}
        {handleWebviewExternalLink(
          isGrandeGp ? `${getSlug().url}/gp-sp-2023/regulamento` : `${getSlug().url}/regulamento`,
          "Regulamento da promoção.",
          false
        )}
      </Text>
      <TitleForm marginBottom=".5rem" id="main">
        DADOS CADASTRAIS
      </TitleForm>

      <form onSubmit={formik.handleSubmit}>
        <ContentForm>
          {loginError?.includes("Dados alterados com sucesso!") && (
            <MessageBox bgMessage={theme.color.blue}>
              Seus dados cadastrais foram alterados com sucesso!
            </MessageBox>
          )}
          {!loginError?.includes("Dados alterados com sucesso!") &&
            loginError && (
              <MessageBox bgMessage={theme.color.red}>{loginError}</MessageBox>
            )}
          {!loginError?.includes("Dados alterados com sucesso!") &&
            registerError && (
              <MessageBox bgMessage={theme.color.red}>
                {registerError}
              </MessageBox>
            )}
          <WrapInput
            error={formik.errors?.name && formik.touched?.name}
            isAnoTodo={isAnoTodo}
          >
            <TextField
              id={"input"}
              label="MEU NOME"
              type="text"
              onTrailingIconSelect={() => this.setState({ value: "" })}
            >
              <Input
                id="name"
                aria-label="MEU NOME"
                name="name"
                type="text"
                //@ts-ignore
                onChange={(e: any) => {
                  //@ts-ignore
                  formik.setFieldValue(
                    "name",
                    e.target.value.replace(/[^a-zA-Z ]/g, "")
                  );
                }}
                value={formik.values.name}
              />
            </TextField>
            {formik.errors?.name && formik.touched.name && (
              <ErrorMessage
                tabIndex={0}
                role="text"
                error={Boolean(formik.errors?.name)}
                aria-label="O campo Meu Nome é obrigatório"
                bgMessage={theme.color.red}
              >
                {formik.errors?.name}
              </ErrorMessage>
            )}
          </WrapInput>
          <Spacing />

          <WrapInput
            error={formik.errors?.cpf && formik.touched.cpf}
            isAnoTodo={isAnoTodo}
            style={isAuthenticated ? { opacity: 0.7 } : { opacity: 1 }}
          >
            <Rifm
              accept={/\d+/g}
              format={formatCpf}
              value={formik.values?.cpf}
              onChange={(e) => {
                formik.setFieldValue("cpf", e);
              }}
            >
              {({ value, onChange }) => (
                <TextField
                  id={
                    loginError?.includes("Seu CPF já está cadastrado.")
                      ? "error"
                      : "input"
                  }
                  label="CPF"
                  name="cpf"
                  onTrailingIconSelect={() => this.setState({ value: "" })}
                >
                  <Input
                    id="cpf"
                    aria-label="cpf"
                    disabled={isAuthenticated}
                    name="cpf"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </TextField>
              )}
            </Rifm>
            {formik.errors?.cpf && formik.touched.cpf && (
              <ErrorMessage
                tabIndex={0}
                role="text"
                error={Boolean(formik.errors?.cpf)}
                aria-label="O campo CPF é obrigatório"
                bgMessage={theme.color.red}
              >
                {formik.errors?.cpf}
              </ErrorMessage>
            )}
          </WrapInput>
          <Spacing />

          <WrapInput
            error={formik.errors?.email && formik.touched.email}
            isAnoTodo={isAnoTodo}
          >
            <TextField
              id={"input"}
              label="E-MAIL"
              // name="email"
              onTrailingIconSelect={() => this.setState({ value: "" })}
            >
              <Input
                id="email"
                aria-label="e-mail"
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </TextField>
            {formik.errors?.email && formik.touched.email && (
              <ErrorMessage
                tabIndex={0}
                role="text"
                error={Boolean(formik.errors?.email)}
                aria-label="O campo E-mail é obrigatório"
                bgMessage={theme.color.red}
              >
                {formik.errors?.email}
              </ErrorMessage>
            )}
          </WrapInput>
          <Spacing />

          <WrapInput
            error={formik.errors?.phone && formik.touched.phone}
            isAnoTodo={isAnoTodo}
          >
            <Rifm
              accept={/\d+/g}
              format={formatPhone}
              value={formik.values.phone}
              onChange={(e) => {
                formik.setFieldValue("phone", e);
              }}
            >
              {({ value, onChange }) => (
                <TextField
                  id={
                    loginError?.includes(
                      "Ops! Esse não parece ser um celular válido."
                    )
                      ? "error"
                      : "input"
                  }
                  label="CELULAR"
                  onTrailingIconSelect={() => this.setState({ value: "" })}
                >
                  <Input
                    id="phone"
                    aria-label="celular"
                    name="phone"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </TextField>
              )}
            </Rifm>
            {formik.errors?.phone &&
              formik.touched.phone &&
              formik.errors?.phone && (
                <ErrorMessage
                  tabIndex={0}
                  role="text"
                  error={Boolean(formik.errors?.phone && formik.touched.phone)}
                  aria-label="O número de telefone deve ter no mínimo 9 digitos"
                  bgMessage={theme.color.red}
                >
                  {formik.errors?.phone}
                </ErrorMessage>
              )}
          </WrapInput>
          <Spacing />

          <WrapInput
            error={formik.errors?.birthday && formik.touched.birthday}
            isAnoTodo={isAnoTodo}
          >
            <Rifm
              accept={/\d+/g}
              format={formatDate}
              value={formik.values.birthday}
              onChange={(e) => {
                formik.setFieldValue("birthday", e);
                let breakDate = e.split("/");
                if (breakDate && breakDate[1]) {
                  if (parseInt(breakDate[0]) > 31) {
                    breakDate.splice(0, 1, "31");
                    formik.setFieldValue("birthday", breakDate.join("/"));
                  }
                  if (parseInt(breakDate[1]) > 12) {
                    breakDate.splice(1, 1, "12");
                    formik.setFieldValue("birthday", breakDate.join("/"));
                  }
                  if (parseInt(breakDate[2]) > new Date().getFullYear()) {
                    breakDate.splice(2, 1, new Date().getFullYear().toString());
                    formik.setFieldValue("birthday", breakDate.join("/"));
                  }
                }
              }}
            >
              {({ value, onChange }) => (
                <TextField
                  id={"input"}
                  label="DATA DE NASCIMENTO"
                  onTrailingIconSelect={() => this.setState({ value: "" })}
                >
                  <Input
                    id="birthday"
                    aria-label="data de nascimento"
                    name="birthday"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </TextField>
              )}
            </Rifm>
            {formik.touched.birthday && formik.errors?.birthday && (
              <ErrorMessage
                tabIndex={0}
                role="text"
                error={Boolean(
                  formik.errors?.birthday && formik.touched.birthday
                )}
                aria-label="A data inserida é inválida"
                bgMessage={theme.color.red}
              >
                {formik.errors?.birthday}
              </ErrorMessage>
            )}
          </WrapInput>
          <Spacing />

          {!isAuthenticated && (
            <WrapInput
              error={formik.errors?.password && formik.touched.password}
              isAnoTodo={isAnoTodo}
            >
              <TextField id={"input"} label="SENHA">
                <Input
                  id="password"
                  aria-label="password"
                  name="password"
                  autoComplete="new-password"
                  type={viewPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  ref={password}
                />
              </TextField>

              <IconContainer
                aria-label={viewPassword ? "esconder" : "mostrar"}
                type="button"
                onClick={() => setViewPassword(!viewPassword)}
              >
                <IconInput
                  name={viewPassword ? "view" : "view-off"}
                  color="#9A9A9A"
                  size="6"
                />
              </IconContainer>

              {formik.touched.password && formik.errors?.password && (
                <ErrorMessage
                  tabIndex={0}
                  role="text"
                  error={Boolean(
                    formik.errors?.password && formik.touched.password
                  )}
                  aria-label="O campo Senha é obrigatório"
                  bgMessage={theme.color.red}
                >
                  {formik.errors?.password}
                </ErrorMessage>
              )}
            </WrapInput>
          )}
        </ContentForm>

        <Box
          width={width > 768 ? "70%" : "100%"}
          margin="0 auto"
          display="flex"
          alignItems="center"
          flexDirection="column"
          marginBottom={width <= 440 ? "25px" : "50px"}
        >
          {!window.location.host.includes("promoshell-dev") &&
            !window.location.host.includes("localhost") &&
            !window.location.host.includes("192") && (
              <CustomReCaptcha>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={localStorage.getItem("promo_recaptcha")}
                  onChange={(val) => {
                    formik.setFieldValue(
                      "reCaptcha",
                      val && val.length > 0 ? true : false
                    );
                    formik.setFieldValue("reCaptchaValue", val);
                  }}
                />
              </CustomReCaptcha>
            )}
          {!isNotRegister && (
            <Text textAlign={"center"} fontSize="1rem">
              Sua senha deve ter entre 8 e 32 caracteres contendo letras e
              números.
            </Text>
          )}
          {isAuthenticated ? (
            <BtnUpdate
              fontWeight={500}
              aria-label="atualizar"
              width="100%"
              height="55px"
              fontSize="16px"
              marginTop="21px"
              borderRadius={0}
              border={isAnoTodo && "none"}
              cursor={"pointer"}
              // isLoading={formik.isSubmitting}
              isDisabled={
                !window.location.host.includes("promoshell-dev") &&
                  !window.location.host.includes("localhost")
                  ? !formik.values.reCaptcha
                  : false
              }
              backgroundColor={"#FBCE07"}
              color={"#58595B"}
              size="lg"
              type="submit"
              _hover={{
                backgroundColor: "#7D7D7D",
                border: `2px solid #7D7D7D`,
                color: theme.color.yellow,
              }}
              _focus={{
                backgroundColor: "#7D7D7D",
                border: `2px solid #7D7D7D`,
                color: theme.color.yellow,
              }}
            >
              {isLoading
                ? "ATUALIZANDO DADOS CADASTRAIS"
                : "ATUALIZAR DADOS CADASTRAIS"}
            </BtnUpdate>
          ) : (
            <BtnUpdate
              cursor="pointer"
              fontWeight={500}
              aria-label="cadastrar"
              borderRadius={0}
              border="none"
              fontSize="16px"
              height="55px"
              marginTop="21px"
              isDisabled={
                !window.location.host.includes("promoshell-dev") &&
                  !window.location.host.includes("localhost")
                  ? !formik.values.reCaptcha
                  : false
              }
              backgroundColor={"#FBCE07"}
              color={"#58595B"}
              size="lg"
              type="submit"
              _hover={{
                backgroundColor: theme.color.white,
                border: `3px solid ${theme.color.yellow} `,
                color: theme.color.darkGray,
              }}
              _focus={{
                backgroundColor: theme.color.white,
                border: `3px solid ${theme.color.yellow} `,
                color: theme.color.darkGray,
              }}
            >
              {isLoading ? "CADASTRANDO" : "CADASTRAR"}
            </BtnUpdate>
          )}
        </Box>
      </form>
      {isAuthenticated && (isAnoTodo || isGrandeGp) && (
        <Box marginTop="0px" marginBottom="40px">
          <TitleForm marginTop={"1.5rem"} as="h2">
            ALTERAR SENHA
          </TitleForm>
          <form onSubmit={formikPassword.handleSubmit}>
            <ContentForm>
              {passwordError?.includes("Senha alterada com sucesso") && (
                <ErrorMessage
                  tabIndex={0}
                  role="text" bgMessage={theme.color.blue}>
                  Sua senha foi alterada com sucesso!
                </ErrorMessage>
              )}
              {!passwordError?.includes("Senha alterada com sucesso") &&
                passwordError && (
                  <ErrorMessage
                    tabIndex={0}
                    role="text" bgMessage={theme.color.red}>
                    {passwordError}
                  </ErrorMessage>
                )}
              {formikPassword.errors.confirmPassword ? (
                <ErrorMessage
                  tabIndex={0}
                  role="text" bgMessage={theme.color.red}>
                  {formikPassword.errors.confirmPassword}
                </ErrorMessage>
              ) : (
                formikPassword.errors.newPassword && (
                  <ErrorMessage
                    tabIndex={0}
                    role="text" bgMessage={theme.color.red}>
                    {formikPassword.errors.newPassword}
                  </ErrorMessage>
                )
              )}
              <Spacing />
              <WrapInput
                error={
                  formikPassword.errors?.currentPassword &&
                  formikPassword.touched.currentPassword
                }
                isAnoTodo={isAnoTodo}
              >
                <TextField id={"input"} label="SENHA ATUAL">
                  <Input
                    aria-label="SENHA ATUAL"
                    name="currentPassword"
                    type={viewCurrentPassword ? "text" : "password"}
                    onChange={formikPassword.handleChange}
                    value={formikPassword.values.currentPassword}
                  />
                </TextField>
                <IconContainer
                  aria-label={viewCurrentPassword ? "esconder" : "mostrar"}
                  type="button"
                  onClick={() => setViewCurrentPassword(!viewCurrentPassword)}
                >
                  <IconInput
                    name={viewCurrentPassword ? "view" : "view-off"}
                    color="#9A9A9A"
                    size="6"
                  />
                </IconContainer>
              </WrapInput>
              <Spacing />
              <WrapInput
                error={
                  formikPassword.errors?.newPassword &&
                  formikPassword.touched.newPassword
                }
                isAnoTodo={isAnoTodo}
              >
                <TextField id={"input"} label="NOVA SENHA">
                  <Input
                    aria-label="NOVA SENHA"
                    name="newPassword"
                    type={viewNewPassword ? "text" : "password"}
                    onChange={formikPassword.handleChange}
                    value={formikPassword.values.newPassword}
                  />
                </TextField>
                <IconContainer
                  aria-label={viewNewPassword ? "esconder" : "mostrar"}
                  type="button"
                  onClick={() => setViewNewPassword(!viewNewPassword)}
                >
                  <IconInput
                    name={viewNewPassword ? "view" : "view-off"}
                    color="#9A9A9A"
                    size="6"
                  />
                </IconContainer>
              </WrapInput>
              <Spacing />
              <WrapInput
                error={
                  formikPassword.errors?.confirmPassword &&
                  formikPassword.touched.confirmPassword
                }
                isAnoTodo={isAnoTodo}
              >
                <TextField id={"input"} label="CONFIRMAR SENHA">
                  <Input
                    aria-label="CONFIRMAR SENHA"
                    name="confirmPassword"
                    type={viewNewConfirmPassword ? "text" : "password"}
                    onChange={formikPassword.handleChange}
                    value={formikPassword.values.confirmPassword}
                  />
                </TextField>
                <IconContainer
                  aria-label={viewNewConfirmPassword ? "esconder" : "mostrar"}
                  type="button"
                  onClick={() => setViewNewConfirmPassword(!viewNewConfirmPassword)}
                >
                  <IconInput
                    name={viewNewConfirmPassword ? "view" : "view-off"}
                    color="#9A9A9A"
                    size="6"
                  />
                </IconContainer>
              </WrapInput>
              <Spacing />
            </ContentForm>
            <Box
              width={width > 768 ? "70%" : "auto"}
              margin="0 auto"
              display="flex"
              alignItems="center"
              flexDirection="column"
              marginBottom="50px"
            >
              <span>
                Sua senha deve ter entre 8 e 32 caracteres contendo letras e
                números.
              </span>
              <Spacing />
              <BtnUpdate
                isLoading={formikPassword.isSubmitting}
                fontWeight={500}
                borderRadius={0}
                aria-label="ALTERAR SENHA"
                height="55px"
                width="100%"
                fontSize="1rem"
                marginTop="21px"
                border="none"
                backgroundColor={"#FBCE07"}
                color={"#58595B"}
                size="lg"
                fontFamily={(props) => props.theme.Shell.Bold}
                textTransform={"uppercase"}
                type="submit"
              >
                {isLoading ? "alterando minha senha" : "alterar minha senha"}
              </BtnUpdate>
            </Box>
          </form>
        </Box>
      )}
    </ContainerLocal>
  );
}

const ContainerLocal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
`;

const MyLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.color.orange};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const MessageBox = styled.p`
  transition: 0.3s;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial;
  transition: 0.2s ease-in-out;
  color: ${(props) => props.theme.color.white};
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  height: 37px;
  background-color: ${(props) => props.bgMessage};
  text-align: center;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    padding: 0rem 1rem;
    margin: 0 0 10px 0;
    height: 52px;
  }
`;

const TitleForm = styled(Box)`
  margin-top: ${(props) =>
    props.marginTop !== undefined ? props.marginTop : "0rem"};
  margin-bottom: 0em;
  font-family: ${(props) => props.theme.Shell.Bold};
  text-align: center;
  color: #58595b;
  font-size: 20px;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-top: 20px;
    font-size: 18px;
    text-align: left;
  }
`;

const Spacing = styled.div`
  margin-bottom: 20px;
`;

const WrapInput = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  #input {
    width: 100%;
    font-family: ${(props) => props.theme.Shell.Book};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    background: ${(props) =>
    props.isAnoTodo && !props.error
      ? "transparent"
      : props.error
        ? "rgba(255, 0, 0, 0.05)"
        : ""} !important;
    .mdc-floating-label {
      color: ${(props) => props.theme.color.gray} !important;
      letter-spacing: 0.03em !important;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 16px !important;
      font-family: ${(props) => props.theme.Shell.Bold};
    }
    .mdc-line-ripple {
      background-color: ${(props) =>
    props.error ? props.theme.color.red : "unset"} !important;
    }

    .mdc-floating-label--float-above {
      color: ${(props) => props.theme.color.darkGray} !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 14px !important;
      font-family: ${(props) => props.theme.Shell.Bold} !important;
    }
    .mdc-text-field__input {
      font-size: 20px;
      font-family: ${(props) => props.theme.Shell.Book};
    }
    input {
      color: ${(props) => props.theme.color.gray} !important;
      font-size: 20px !important;
      font-weight: 0 !important;
    }
  }

  #error {
    width: 100%;
    label {
      font-family: Futura LT Regular !important;
    }
    .mdc-floating-label {
      color: ${(props) => props.theme.color.red} !important;
      letter-spacing: 0.03em !important;
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 14px !important;
    }

    .mdc-floating-label--float-above {
      color: ${(props) => props.theme.color.red} !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 14px !important;
    }
    input {
      color: ${(props) => props.theme.color.red} !important;
      font-size: 20px !important;
      font-weight: normal !important;
    }
  }
`;

const CustomReCaptcha = styled(Box)`
  margin-right: -0.1rem;
  margin-bottom: 5px;

  transform: scale(1.1);

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    transform: scale(1);
    margin-bottom: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    transform: scale(1.2);
    margin-bottom: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 375px) {
    transform: scale(1.1);
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0px;
  }

  @media (max-width: 360px) {
    transform: scale(1.08);
  }

  @media (max-width: ${(props) => props.theme.queries.i5}) {
    transform: scale(0.95);
    margin-left: 0px;
  }
`;

const BtnUpdate = styled(Button)`
  font-size: 16px !important;
  width: 335px !important;
  font-family: ${(props) => props.theme.Shell.Bold};
  @media (min-width: ${(props) => props.theme.queries.medium}) {
    &:focus {
      transition: 0.3s !important;
      background-color: #7d7d7d !important;
      color: ${(props) => props.theme.color.yellow} !important;
    }
  }
  &:hover {
    transition: 0.3s !important;
    background-color: #7d7d7d !important;
    color: ${(props) => props.theme.color.yellow} !important;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 304px !important;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 96% !important;
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    font-size: 14px !important;
    width: 100% !important;
  }
`;

const IconContainer = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 8px;
  cursor: pointer;

  &:hover, &:focus {
    svg {
      color: ${props => props.theme.color.red};
    }
  }
`

const IconInput = styled(Icon)`
  /* position: absolute; */
  /* right: 0; */
  /* margin-top: 14px;
  margin-right: 5px; */
  /* cursor: pointer; */
`;

const ErrorMessage = styled.p`
  font-size: 1.125rem;
  line-height: 100%;
  font-family: ${(props) => props.theme.Shell.Medium};
  margin: 0;

  color: ${(props) => props.theme.color.red};
  background-color: transparent;
  padding-top: 0.5rem;
`;
