import useWindowSize from "hooks/useWIndowSize";
import React, { ReactNode } from "react"
import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css';
import { SorteioCardWrapper } from "../styles";

interface CarouselResponsiveProps {
  [key: string]: {
    breakpoint: {
      max: number
      min: number
    }
    items: number
    slidesToSlide?: number
    partialVisibilityGutter?: number
  }
}

export function RenderCarousel({ children }: { children: ReactNode }) {
  const { width } = useWindowSize()
  const responsive: CarouselResponsiveProps = {
    desktop: {
      breakpoint: { max: 4000, min: 1280 },
      items: 4,
      partialVisibilityGutter: 30
    },
    tabletXl: {
      breakpoint: { max: 1279, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
      partialVisibilityGutter: 10
    },
    mobileLg: {
      breakpoint: { max: 767, min: 540 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 539, min: 425 },
      items: 1,
      partialVisibilityGutter: 170
    },
     mobile425: {
      breakpoint: { max: 424, min: 415 },
      items: 1,
      partialVisibilityGutter: 130,
    },
    mobile414: {
      breakpoint: { max: 414, min: 376 },
      items: 1,
      partialVisibilityGutter: 140,
    },
      mobile375: {
      breakpoint: { max: 375, min: 361 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    mobile360: {
      breakpoint: { max: 360, min: 336 },
      items: 1,
      partialVisibilityGutter: 100,
    },
    mobilei5: {
      breakpoint: { max: 335, min: 320 },
      items: 1,
      partialVisibilityGutter: 70,
    },
     mobile256: {
      breakpoint: { max: 319, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  }
  
  if (width >= 1200) {
    return <SorteioCardWrapper>{children}</SorteioCardWrapper>
  }

  return (
    <Carousel
      responsive={responsive}
      partialVisbile
      arrows={false}
      minimumTouchDrag={30}
    >
      {children}
    </Carousel>
  )
}