import React, { useState } from "react";
import { Box, Spinner } from "@chakra-ui/core";
import { useActions, useStore } from "configureStore";
import useWindowSize from "hooks/useWIndowSize";
import { ProductCardLabel } from "./ProductCardLabel";
import {
  BoxPriceParagraph,
  BoxQuero,
  ButtonQueroProducts,
  CategorySection,
  ContainerSection,
  HeadingCategory,
  ProductCard,
} from "./SectionProductCardsStyles";
import {
  ImageCarousel,
  ImageContainer,
} from "modules/common/ConhecaProdutoStyles";
import IconDownload from "assets/images/anoTodo/icons/download-icon.svg";

interface Props {
  handleRedeemSelectedProduct: (productId: number, sessionKey: string, formSSOId: string) => void;
  sessionStorageKey: string
  formElementId: string
}

export default function ProductsCategory({
  handleRedeemSelectedProduct,
  sessionStorageKey,
  formElementId
}: Props) {
  const categoryProducts = useStore((state) => state.anoTodo.categoryProducts);
  const isLoadingCatProd = useStore(
    (state) => state.anoTodo.isLoadingCatProducts
  );
  const { width } = useWindowSize();
  const [mouseXpos, setMouseXpos] = useState<number>(null);
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount);

  function handleGridColAmmount(arrayLength: number) {
    if (width < 1024) {
      return Math.ceil(arrayLength / 2);
    } else {
      return Math.ceil(arrayLength / 4);
    }
  }

  function handleSlideClick(product: any, mousePos: number) {
    let xPos = mousePos;
    if (xPos - mouseXpos === 0) {
      handleRedeemSelectedProduct(Number(product?.id_produto), sessionStorageKey, formElementId);
    }
  }

  const imgResponsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 1023, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {categoryProducts?.map((category, ctidx) => (
        <CategorySection
          isShown={category.produtos.length > 0}
          key={category.nome}
        >
          {Boolean(category?.imagem_desktop && category?.imagem_mobile) && (
            <img
              src={
                width < 1024 ? category.imagem_mobile : category.imagem_desktop
              }
              alt="Imagem de cabeçalho da categoria"
            />
          )}
          {/* <HeadingCategory
            key={ctidx}
            fontColor={category.cor_fonte}
            bgColor={category.cor_fundo}
          >
            {category.nome}
          </HeadingCategory> */}
          <ContainerSection
            gridLines={() => handleGridColAmmount(category.produtos.length)}
          >
            {category.produtos.map((product) => (
              <ProductCard
                id={`product-${product.id_produto}`}
                key={product.nome}
              >
                {isLoadingCatProd ? (
                  <Box
                    w={"100%"}
                    d={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="yellow.500"
                      size="xl"
                    />
                  </Box>
                ) : (
                  <>
                    <ProductCardLabel
                      title={product.tags[0]?.titulo}
                      bgColor={product.tags[0]?.cor_fundo}
                      titleColor={product.tags[0]?.cor_titulo}
                    />

                    <ImageCarousel
                      responsive={imgResponsive}
                      showDots
                      infinite
                      arrows={false}
                      renderDotsOutside
                      isAnoTodo={true}
                    >
                      {product.small_images.map(
                        ({ arquivo, descricao }, index) => (
                          <ImageContainer
                            shellCost={product.conchas}
                            status={null}
                            index={index}
                            key={product.id_produto}
                            isAnoTodo
                          >
                            <img
                              src={arquivo}
                              alt={`Imagens do Produto ${product.nome}`}
                              draggable="false"
                              onClick={(e) => {
                                handleSlideClick(product, e.pageX);
                              }}
                              onMouseDown={(e) => {
                                setMouseXpos(e.pageX);
                              }}
                              onMouseUp={(e) => {
                                handleSlideClick(product, e.pageX);
                              }}
                            />
                          </ImageContainer>
                        )
                      )}
                    </ImageCarousel>

                    <Box
                      d={"flex"}
                      flexDir={"column"}
                      w={"95%"}
                      m={"auto"}
                      position={"relative"}
                    >
                      <h2>{product.nome}</h2>
                      {width >= 1024 && (
                        <Box>
                          <p>{product.descricao}</p>
                          <Box>
                            {Boolean(product.manual) && (
                              <a
                                href={product.manual}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Ao clicar no link será aberto uma nova aba com um arquivo no formato PDF."
                                download
                              >
                                <img
                                  className="dlicon"
                                  src={IconDownload}
                                  alt=""
                                  draggable="false"
                                />
                                Baixar manual
                              </a>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <BoxQuero>
                      <BoxPriceParagraph indisponivel={product.indisponivel}>
                        {product.conchas}{" "}
                        <b>
                          {Number(product.conchas) === 1 ? "concha" : "conchas"}
                        </b>{" "}
                        {product.preco_com_conchas && (
                          <>
                            <span className="red">+</span> <br />{" "}
                            <strong>{product.preco_com_conchas}</strong>
                          </>
                        )}
                        {product.preco_sem_conchas && (
                          <Box mt={"4px"} mb={["6px", "6px", "8px"]}>
                            {" "}
                            <span>ou {product.preco_sem_conchas}</span>
                          </Box>
                        )}
                      </BoxPriceParagraph>
                      {product.indisponivel === false &&
                        (buttonClickCount < 1 ? (
                          <ButtonQueroProducts className={"disabled"}>
                            <Spinner />
                          </ButtonQueroProducts>
                        ) : (
                          <ButtonQueroProducts
                            onClick={() => {
                              handleRedeemSelectedProduct(
                                Number(product.id_produto),
                                sessionStorageKey,
                                formElementId
                              );
                            }}
                          >
                            quero!
                          </ButtonQueroProducts>
                        ))}
                    </BoxQuero>
                  </>
                )}
              </ProductCard>
            ))}
          </ContainerSection>
        </CategorySection>
      ))}
    </>
  );
}
