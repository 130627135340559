import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, useDisclosure } from "@chakra-ui/core";
import { WebdoorAnoTodo } from "../components/Home/Webdoor/WebdoorAnoTodo";
import SectionYellowCards from "../components/Home/SectionYellowCards";
import SectionProductCards from "../components/Home/SectionProductCards/SectionProductCards";
import FooterAnoTodo from "../components/FooterAnoTodo";
import "react-multi-carousel/lib/styles.css";
import { auth as useAuth } from "hooks/auth";
import { useActions, useStore } from "configureStore";
import { CarouselModal } from "../components/Home/Webdoor/CarouselModal";

import PromoDesktop from "assets/images/anoTodo/img-modal.png";
import PromoMobile from "assets/images/anoTodo/img-modal-mobile.png";
import ImageTrocaContato from "assets/images/anoTodo/troca-contato.png";

export default function HomeAnoTodo() {
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");
  const getIsPromoFooter = useActions((action) => action.anoTodo.getIsPromoFooter);
  const getProducts = useActions((action) => action.anoTodo.getProducts);
  const getCatProducts = useActions((action) => action.anoTodo.getCategoriesProducts);
  const getCarouselCards = useActions((action) => action.anoTodo.getCarouselCards);
  const getCarouselRestricted = useActions((action) => action.anoTodo.getCarouselRestricted);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const setButtonClickCount = useActions((action) => action.anoTodo.setButtonClickCount);
  const isRedirectReady = JSON.parse(sessionStorage.getItem("productSSO"))?.isRedirectReady;

  useEffect(() => {
    // onOpen();
    setTimeout(() => {
      getIsPromoFooter();
    }, 200);
  }, [])

  useEffect(() => {
    document.title = "Promoshell"
    getProducts();
    getCatProducts();
    getCarouselCards();
    if (isAuthenticated) {
      getCarouselRestricted();
    }
  }, [isAuthenticated, isShellbox])

  useEffect(() => {
    if (isRedirectReady) {
      setButtonClickCount(0);
    } else {
      setButtonClickCount(1);
    }
  }, [isRedirectReady]);

  return (
    <Container
      isAuth={isAuthenticated}
      isShellbox={isShellbox}
    >
      <WebdoorAnoTodo />
      <Box
        as={"main"}
        display={"flex"}
        flexDir={"column"}
        w={"90%"}
        maxW={"65.875rem"}
        p={["18px 0", "18px 0", "18px 0", "20px 0"]}
        m={"0 auto"}
      >
        <SectionYellowCards isAuth={isAuthenticated} />
        <SectionProductCards isAuth={isAuthenticated} />
      </Box>
      {(!isAuthenticated && !isShellbox) && (
        <FooterAnoTodo />
      )}
      {/* <CarouselModal
        isOpen={isOpen}
        onClose={onClose}
        imageLarge={ImageTrocaContato}
        imageMobile={ImageTrocaContato}
      /> */}
    </Container>
  )
}

const Container = styled(Box)`
  margin-top: ${(props) => (!props.isShellbox ? "40px" : "0")};
  background-color: ${props => props.theme.color.lightGray50};
  
  padding-bottom: ${props => props.isAuth || props.isShellbox ? "3rem" : "0"};
  margin-bottom: ${props => props.isShellbox ? "2rem" : "0"};
`;