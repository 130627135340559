import { BoxProps, Text } from "@chakra-ui/core";
import React, { ReactNode } from "react";
import { theme } from "theme";

interface Props extends BoxProps {
  children: ReactNode;
  fontSize?: string | string[];
  color?: string;
}

export function TextCustom({ children, fontSize = ["1.75rem","1.75rem","1.75rem","1.5rem"], ...rest }: Props) {
  return (
    <Text
      color={"#fff"}
      fontFamily={theme.FuturaFontBold}
      fontSize={fontSize}
      lineHeight={"120%"}
      textTransform={"uppercase"}
      m={0}
      {...rest}
    >
      {children}
    </Text>
  )
}