import styled from "styled-components";
import { ButtonQuero } from "../SectionYellowCards";

export const ContainerSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(${(props) => props.gridLines}, 336px);
  grid-gap: 10px;

  width: 100%;
  max-width: 25rem;
  margin: 1rem auto 0;
  padding: 1rem 0;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${(props) => props.gridLines}, 484px);
    grid-gap: 20px;
    max-width: 40rem;
  }
  @media (min-width: ${(props) => props.theme.queries.desk}) {
    grid-template-columns: repeat(4, 246px);
    grid-template-rows: repeat(${(props) => props.gridLines}, 484px);
    grid-gap: 20px;
    max-width: 66rem;
  }
`;

export const ProductCard = styled.div`
  width: 100%;
  /* height: 306px; */

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 10rem 4rem auto;
  align-items: center;

  border-radius: 2px;
  background-color: #fff;
  margin: 0 auto;
  padding: 8px;

  text-align: center;
  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
  box-sizing: border-box;
  position: relative;

  h2 {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 0.688rem;
    line-height: 100%;
    text-transform: uppercase;
    max-width: 100%;
    padding: 0 3px;
    color: ${(props) => props.theme.color.darkGray};
  }

  p {
    font-family: Arial;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.938rem;
    margin: 0 auto;
    color: ${(props) => props.theme.color.text};
  }

  a {
    font-family: ${(props) => props.theme.helvetica};
    font-weight: 500;
    font-size: 0.75rem;
    line-height: ${(props) => props.theme.fontSize.text};
    color: ${(props) => props.theme.color.shellPurple};

    img.dlicon {
      margin-bottom: -2px;
      margin-right: 4px;
    }
  }
  ul.react-multi-carousel-dot-list {
    bottom: unset;
  }
  li.react-multi-carousel-dot {
    button {
      background: ${(props) => props.theme.color.mediumGray3};
      border: none;
      width: 6.5px;
      height: 6.5px;
      &:focus {
        outline: auto;
      }
    }
  }
  li.react-multi-carousel-dot--active {
    button {
      background: ${(props) => props.theme.color.yellow};
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 100%;
    height: 30.25rem;
    max-width: 20.75rem;
    margin: 0 auto;

    grid-template-rows: auto 6rem 7.25rem;

    h2 {
      font-size: ${(props) => props.theme.fontSize.normal};
      line-height: 100%;
      margin: 5px auto;
    }
  }
`;

export const BoxQuero = styled.div`
  width: 100%;
  height: 88px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  padding: 4px;

  background: ${(props) => props.theme.color.lightYellow50};
  box-sizing: border-box;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    height: 106px;
  }
`;

export const BoxPriceParagraph = styled.div`
  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 0.688rem;
  line-height: 100%;
  width: 95%;

  margin-top: 0.375rem;
  /* margin-bottom: 4px; */
  margin-bottom: auto;

  strong {
    text-transform: uppercase;
    padding: 0;
    b {
      text-transform: none;
    }
    &.red {
      color: ${(props) => props.theme.color.red};
    }
  }

  span {
    font-family: ${(props) => props.theme.Shell.Book};
    &.red {
      color: ${(props) => props.theme.color.red};
    }
  }
  @media (min-width: ${(props) => props.theme.queries.small}) {
    br {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    font-size: 0.938rem;
    line-height: 100%;
    width: 100%;

    strong {
      font-size: inherit;

      &.red {
        margin-top: 6px;
      }
    }
    margin-top: 0.75rem;
    margin-bottom: auto;
  } 
`;

export const ButtonQueroProducts = styled(ButtonQuero)`
  width: 100%;
`;

export const ContainerCategory = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 25rem;
  /* margin: 1rem auto; */
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    max-width: 40rem;
  }
  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 65.25rem;
  }
`;

export const CategorySection = styled.article`
  display: ${props => props.isShown ? "flex" : "none"};
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
  
  @media (min-width: ${props => props.theme.queries.desk}) {
    margin: 1rem 0 0;
  }
`;

export const HeadingCategory = styled.h2`
  display: block;
  width: 100%;

  background-color: ${props => props.bgColor || "transparent"};
  color: ${props => props.fontColor || props.theme.color.text};
  line-height: 1;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSize.text};
  letter-spacing: 0.5px;
  font-family: ${props => props.theme.Shell.Bold};
  
  margin: 0;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
`;