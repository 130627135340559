import { Box } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components";
import NavigationTabs from "./NavigationTabs";
// import { useActions, useStore } from "configureStore";
// import { useRouter } from "hooks/useRouter";
// import { getSlug } from "utils/helper";
// Assets
import BoxShell from "assets/icons/box-shell.svg"
import { useStore } from "configureStore";

export function NavigationAnoTodo() {
  // const getShells = useActions((action) => action.anoTodo.getShells);
  // const router = useRouter();
  // const slug = getSlug().url;
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated)

  return (
    <Navigation isAuth={isAuthenticated}>
      <IconWrapper
        isAuth={isAuthenticated}
        // onClick={() => router.push(`${slug}/todos-produtos`)}
      >
        <img src={BoxShell} alt="" />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          flexDir={"column"}
          marginLeft={"0.75rem"}
        >
          <strong>
            junte<br />
            <span>&</span>Troque
          </strong>
          <span>o ano todo</span>
        </Box>
      </IconWrapper>

      <NavigationTabs />
    </Navigation>
  )
}

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 65rem;

  margin-bottom: ${props => props.theme.fontSize.text};
  margin-top: 1.125rem;

  @media (min-width: ${props => props.theme.queries.medium}) {
    margin-bottom: 1.125rem;
    margin-top: ${props => props.isAuth ? "0.75rem" : "1.125rem"};
    width: 95%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.isAuth ? "flex-start" : "center"};
  align-items: center;
  width: 100%;
  cursor: pointer;
  
  font-family: ${props => props.theme.Shell.Bold};
  box-sizing: border-box;

  img {
    height: 2rem;
    margin-bottom: 8px;
  }  
  span {
    color: ${props => props.theme.color.yellow};
    text-transform: lowercase;
    font-size: 0.75rem;
    line-height: 95%;
    margin-top: 2px;
  }
  strong {
    font-size: 1.5rem;
    line-height: 90%;
    color: #FFF;
    text-transform: capitalize;
    span {
      font-size: 1.5rem;
      text-transform: unset;
      margin-top: unset;
    }
  }

  @media (min-width: ${props => props.theme.queries.i5}) {
    /* transform: scale(85%); */
  }
  @media (min-width: ${props => props.theme.queries.medium}) {
    max-width: 14rem;
    transform: scale(135%) translate(15%, 10%);
    justify-content: flex-start;
  }
  @media (min-width: ${props => props.theme.queries.desk}) {
    transform: scale(135%) translate(10%, 10%);
  }
`;