import { useStore } from "configureStore"
import Navbar from "modules/common/Navbar/Navbar"
import NavbarSmall from "modules/common/Navbar/NavbarSmall"
import ShellboxBottomMenu from "modules/promoAnoTodo/components/ShellboxBottomMenu"
import React from "react"
import { getSlug } from "utils/helper"

export function SmartNavbar() {
  const isAuth = useStore((state) => state.auth.isAuthenticated)
  const slug = getSlug().url
  const pathname = window.location.pathname
  
  if (pathname === "/" ||
    pathname.includes("/promocoes-de-rodovia") ||
    pathname.includes("/ofertas-shell-select") ||
    pathname.includes("/campanhas-de-lubrificantes") ||
    pathname.includes("/beneficios-app-shell-box")
  ) {
    return <Navbar auth={isAuth} />
  }

  if (pathname.includes(slug) && !pathname.includes("gp-sp-2023")) {
    return (
      <>
        <NavbarSmall auth={isAuth} />
        <ShellboxBottomMenu />
      </>
    )
  }

  return <NavbarSmall auth={isAuth} />
}