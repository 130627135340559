import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { withRouter, useHistory, useLocation, NavLink } from "react-router-dom";
import "@material/react-text-field/dist/text-field.css";
import queryString from "query-string";
import { Box, Button, useDisclosure } from "@chakra-ui/core";
import useWindowSize from "hooks/useWIndowSize";
import { theme } from "theme";
import { useStore, useActions, store, initialData } from "configureStore";
import UpdatePersonalDataModal from "modules/common/UpdataPersonalDataModal";

import BannerShellPromo from "modules/promocaoJunteTroque/Navbar/BannerShellPromo";
import MenuMobile from "modules/common/Navbar/Menu";
import { TopBar } from "modules/common/UI";

import userIcon from "assets/icons/user-icon.svg";
import arrowBack from "assets/images/menos15/arrow-left.svg";
import ModalLogin from "modules/common/Navbar/ModalLogin";
import LinksPromotionResponsive from "modules/promocaoJunteTroque/Navbar/LinksPromotionResponsive";
import { getSlug } from "utils/helper";
import { Link } from "evergreen-ui";
import useShellbox from "hooks/useShellbox";
import { useRouter } from "hooks/useRouter";

import ShellIcon from "assets/icons/shell-icon-new.svg";
import { NewModalLogin } from "./NewLoginModal";

const NavbarSmall = (props) => {
  const { push } = useHistory();
  const location = useLocation();
  const slug = getSlug().url;
  const query = queryString.parse(props.location.search);
  const isShellbox = useShellbox();
  const isAnoTodo = window.location.pathname.includes("junte-troque");
  const isGrandeGp = window.location.pathname.includes("gp-sp-2023");
  const finalRef = useRef(null);
  const isOpened = useStore((state) => state.home.isOpen);
  const user = useStore((state) => state.user.user);
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);
  const clearAuth = useActions((action) => action.auth.clearAuth);
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [openMenu, setOpenMenu] = useState(false);
  const router = useRouter();

  const { width } = useWindowSize();
  const isMobile = width <= 768

  const handleModalLoginClick = () => {
    if (isAuthenticated) {
      clearAuth('')
      return
    }
    setIsOpen(!isOpened);
  };

  function handleNavbarLogoClick() {
    if (slug.includes("junte-troque")) {
      if (location.pathname.includes("gp-sp-2023")) {
        push(`${slug}/gp-sp-2023`)
        return
      }
      push(`${getSlug().url}`);
    } else {
      window.scroll({
        behavior: "smooth",
        top: 0,
      });
    }
  }

  return (
    <>
      <Content
        display={
          // queryshellBox?.includes("hiddenComponent")
          isShellbox ? "none !important" : "flex"
        }
      >
        <ContentBox
          isAnoTodo={isAnoTodo}
          style={
            isAnoTodo && location.pathname.includes("?shellbox")
              ? { display: "none" }
              : { display: "flex" }
          }
        >
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
            width={["90%", "90%", "90%", "90%", "100%"]}
            height={"100%"}
            margin={["0 auto"]}
            position={"relative"}
          >
            {!location.search.includes("shellbox") &&
              location.pathname.includes("ofertas-shell-select/detalhe") && (
                <ArrowBack
                  src={arrowBack}
                  alt="voltar"
                  onClick={() => {
                    push("/ofertas-shell-select");
                  }}
                />
              )}
            <Logo onClick={handleNavbarLogoClick} id="index">
              {window.location.pathname.includes("junte-troque") ||
                window.location.pathname.includes("grande-gp") ?
                (
                  <img src={ShellIcon} alt="" />
                ) : (
                  <p>
                    promo<span>shell</span>
                  </p>
                )}
            </Logo>
            <Box
              display={"flex"}
            >
              {Boolean(isAuthenticated && !isMobile) && (
                <UserTagName
                  to={isGrandeGp ? `${slug}/gp-sp-2023/cadastro` : `${slug}/cadastro`}
                >
                  Bem-vindo, {user?.name || "User"}
                </UserTagName>
              )}
              <LoginButton onClick={handleModalLoginClick}>
                {isOpened && !isAuthenticated && "Fechar"}
                {!isOpened && !isAuthenticated && "Entrar"}
                {isAuthenticated && "Sair"}
              </LoginButton>
            </Box>
            <NewModalLogin />
          </Box>
        </ContentBox>
        <UpdatePersonalDataModal isOpen={isOpen} onClose={onClose} />
      </Content>
      {window.location.pathname === "/" && <BannerShellPromo />}
      {window.location.pathname === `${getSlug().url}/cadastro` &&
        !window.location.pathname.includes("grande-gp") &&
        !window.location.pathname.includes("junte-troque") && (
          <TopBar title={isAuthenticated ? "Meu Cadastro" : "Cadastre-se"} />
        )}
      {window.location.pathname === "/minhas-promocoes" && (
        <TopBar title="Promoções" />
      )}
      {window.location.pathname === `${getSlug().url}/duvidas` &&
        !window.location.pathname.includes("junte-troque") &&
        !window.location.pathname.includes("grande-gp") && (
          <>
            <TopBar title="Dúvidas" />
            {!isShellbox && <LinksPromotionResponsive />}
          </>
        )}
      {window.location.pathname === "/termos-de-uso" && (
        <TopBar title="Termos de uso" />
      )}
      {window.location.pathname === "/politica-de-privacidade" && (
        <TopBar title="Política de privacidade" />
      )}
      {window.location.pathname === `${getSlug().url}/regulamento` &&
        !window.location.pathname.includes("junte-troque") &&
        !window.location.pathname.includes("grande-gp") &&
        (
          <>
            <TopBar title="Regulamento" />
            {!isShellbox && <LinksPromotionResponsive />}
          </>
        )}
      {window.location.pathname === `${getSlug().url}/esqueceu-a-senha` &&
        !window.location.pathname.includes("junte-troque") &&
        !window.location.pathname.includes("grande-gp") && (
          <TopBar title="Recuperar senha" />
        )}
      {window.location.pathname === "/sobre-o-promoshell" && (
        <TopBar title="Sobre o PromoShell" />
      )}
      <MenuMobile onClose={setOpenMenu} isOpen={openMenu} />
    </>
  );
};

export default withRouter(NavbarSmall);

const Content = styled(Box)`
  /* padding: 0 2em; */
  z-index: 12;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.theme.color.white};
  box-shadow: 8px 5px 8px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  height: 45px;
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    padding: 0 0em;
  }
  @media (max-width: ${(props) => props.theme.queries.small}) {
    /* height: 27px; */
    border-bottom: 3px solid ${(props) => props.theme.color.orange};
    padding: 0;
  }
`;

const ContentBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (min-width: ${props => props.theme.queries.lg}) {
    max-width: ${(props) => (props.isAnoTodo ? "1240px" : "1260px")};
  }
`;

const Logo = styled.div`
  display: flex;

  p {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 500;
    color: ${(props) => props.theme.color.orange};
    margin: 0;
    margin-left: 1rem;
    
    span {
      font-family: ${(props) => props.theme.Shell.Bold};
      font-weight: 500;
      color: ${(props) => props.theme.color.red};
    }
  }
`;

const LinkJunteTroque = styled(Link)`
  color: #cf302f;
  text-decoration: none;
  font-family: ${(props) => props.theme.FuturaFont};
  font-size: 15px;
  font-weight: bold;
  line-height: 12px;
  margin-right: 15px;
  border: 1px solid #cf302f;
  padding: 10px 17px;
  max-height: 33px;
  max-width: 252px;
  box-sizing: border-box;

  :hover {
    color: #cf302f;
  }
  @media (max-width: 1366px) {
    max-width: 292px;
  }
`;

const LinkJunteTroqueMobile = styled(Link)`
  color: #cf302f;
  text-decoration: none;
  font-family: ${(props) => props.theme.FuturaFont};
  font-size: 11px;
  font-weight: bold;
  line-height: 12px;
  margin-right: 15px;
  border: 1px solid #cf302f;
  padding: 10px 17px;
  max-height: 33px;
  max-width: 252px;
  box-sizing: border-box;

  :hover {
    color: #cf302f;
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-right: 5px;
    line-height: initial;
    padding: 10px 10px;

    @supports (-webkit-touch-callout: none) {
      width: 110px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 7px;
      line-height: 12px;
    }
  }
`;

const ArrowBack = styled.img`
  cursor: pointer;
  width: 24px;
  height: 17px;
  margin-right: 1rem;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    width: 16px;
    height: 12px;
    margin-left: 37px;
    margin-right: 0;
  }
`;

export const LoginButton = styled.button`
  font-family: ${(props) => props.theme.Shell.Bold};
  color: ${(props) => props.theme.color.red};
  background: transparent;
  font-size: 0.75rem;
  line-height: 0.688rem;

  text-transform: uppercase;
  border: none;
  padding: 0.75rem 1.5rem;
  padding-right: 0;
  margin-left: 1rem;
  cursor: pointer;

  &:hover,
  :focus {
    text-decoration: underline;
  }
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    font-size: ${(props) => props.theme.fontSize.normal};
    padding-right: 0px;
  }
`;

export const UserTagName = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${props => props.theme.Shell.Book};
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  color: #525F7F;
  transition: all .2s;

  &:hover {
    color: ${props => props.theme.color.orange};
  }
`