import { Accordion, AccordionItem } from "@chakra-ui/core"
import React from "react"
import styled from 'styled-components'
import { VideoTranscription } from "./AccordionTranscription"

export function GpVideoSection() {
  return (
    <GpVideoContainer>
      <GpVideoTitle>Saiba como participar</GpVideoTitle>
      <iframe
        // https://youtu.be/G2N0UpZveD8 
        src="https://www.youtube.com/embed/G2N0UpZveD8"
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="YouTube video player"
      >
      </iframe>
      <VideoTranscription />
    </GpVideoContainer>
  )
}

const GpVideoContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 375px;
  gap: 13px;

  iframe {
    width: 100%;
    height: 210px;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    max-width: unset;
    gap: 30px;
    
    iframe {
      width: 90%;
      height: 578px;
    }
  }
`

const GpVideoTitle = styled.h1`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: normal;
  /* text-transform: uppercase; */
  font-family: ${props => props.theme.Shell.Bold};
  color: ${props => props.theme.color.gray};
  text-align: center;
  margin: 0;

  @media (min-width: ${props => props.theme.queries.desk}) {
    font-size: 1.5625rem;
  }
`