import React from 'react'
import styled from 'styled-components'
import QrCodeImage from 'assets/images/grandeGp/qrcode.svg'

export function QrCodeSection() {
  return (
    <QrCodeContainer>
      <QrCodeYellowBlock>
        <p>
          Para participar, aponte a câmera do seu celular para o QR Code e baixe o aplicativo Shell Box
        </p>
      </QrCodeYellowBlock>
      <QrCodeQRBlock>
        <img src={QrCodeImage} alt="Ilustração de uma mão segurando um celular, no qual está inserido o logo de Shell Box. Ao lado, o QR Code com link para baixar o aplicativo" />
      </QrCodeQRBlock>
    </QrCodeContainer>
  )
}

const QrCodeContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;

  @media (min-width: ${props => props.theme.queries.desk}) {
    max-width: unset;
    flex-direction: row;
  }
`

const QrCodeYellowBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${props => props.theme.color.yellow};
  padding: 4.28rem 2rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  
  p {
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: normal;
    font-family: ${props => props.theme.FuturaFontBold};
    color: ${props => props.theme.color.gray};
    margin: 0;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 595px;
  
    p {
      font-size: 1.875rem;
      width: 420px;
    }
  }
  `

const QrCodeQRBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 275px;
  background: ${props => props.theme.color.lightYellow50};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  img {
    width: 100%;
    max-width: 310px;
    margin-top: auto;
    mix-blend-mode: darken;
  }

  @media (min-width: ${props => props.theme.queries.desk}) {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    
    height: 335px;

    img {
      max-width: 370px;
    }
  }
`