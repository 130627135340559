import Rules from "modules/common/Pages/rules"
import React from "react"
import styled from "styled-components"

export default function RulesGp() {
  return (
    <Container>
      <Rules />
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 830px;
  margin: 1rem auto;

  @media (min-width: ${props => props.theme.queries.desk}) {
    margin-top: 1.5rem;
  }
`