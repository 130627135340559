import React, { ReactNode } from "react"
import { LayoutContainer, LayoutContent } from "./styles"
import { PromoHeader } from "../components/PromoHeader"
import { useLocation } from "react-router-dom"
import { PromoFooter } from "../components/PromoFooter"
import { getSlug } from "utils/helper"
import { ButtonWhatsApp } from "./ButtonWhatsApp"
import useWindowSize from "hooks/useWIndowSize"

interface LayoutGpProps {
  children: ReactNode
}

export function LayoutGp({ children }: LayoutGpProps) {
  const location = useLocation()
  const isShellbox = window.location.search.includes("shellbox")
  const { url: slug } = getSlug()
  const { width } = useWindowSize();
  const isMobile320 = width <= 320;

  return (
    <LayoutContainer
      id={"layout-gp"}
      $isShellbox={isShellbox}
    >
      {
        isMobile320 ? <></> : (
          <ButtonWhatsApp />
        )
      }
      <PromoHeader />
      <LayoutContent
        $isHome={location.pathname === `${slug}/gp-sp-2023`}
      >
        {children}
      </LayoutContent>
      <PromoFooter />
    </LayoutContainer>
  )
}
