import { useStoreState as store } from "easy-peasy";
import { initialState } from "../models/auth";

export const auth = (withHook: boolean = true) => {
  const authStorage = localStorage.getItem("Hub-auth");
  let auth: any;
  if (withHook) {
    // @ts-ignore
    auth = store(state => state.auth);
  }
  // @ts-ignore
  const cachedAuth = JSON.parse(authStorage) || {
    auth: initialState
  };

  return {
    auth,
    cachedAuth
  };
};
