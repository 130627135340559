import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import {
  PromoActionButton,
  PromoActionLoginButton,
  PromoHeaderContainer,
  PromoHeaderContent,
  PromoHeaderHeading,
  PromoHeaderNav,
  PromoHeaderNavWrapper,
  PromoHeaderTitle,
  PromoHeaderWebdoor,
  PromoHeaderWebdoorButtons,
  PromoHeaderWebdoorLettering
} from "./styles"
import useWindowSize from "hooks/useWIndowSize"
import { getSlug } from "utils/helper"
import { useStore } from "configureStore"
import { HeaderNavLink } from "./HeaderNavLinks"
import useScrollToModalLogin from "hooks/useScrollToModalLogin"
// Assets
import ImageWebdoorMobile from "assets/images/grandeGp/grandegp-mobile.png"
import ImageWebdoor from "assets/images/grandeGp/grandegp.png"
import ImageVpower from "assets/images/grandeGp/gp-vpower.png"
import IconShellLarge from "assets/images/grandeGp/shell-white-border-icon.png"
import MenuHamburguerMobile from "./MenuHamburguerMobile"

export function PromoHeader() {
  const [heading, setHeading] = useState<{ title: string, description?: string }>({} as any)
  const { url: slug } = getSlug()
  const { width } = useWindowSize()
  const location = useLocation()
  const handleLoginClick = useScrollToModalLogin()
  const isAuth = useStore((state) => state.auth.isAuthenticated)

  function handleHeadingInfo() {
    if (location.pathname === `${slug}/gp-sp-2023`) {
      return
    }
    if (location.pathname.includes("numeros-da-sorte")) {
      setHeading({
        title: "Meus Números da Sorte",
        description: "Selecione a data do sorteio para conferir todos os números acumulados. Boa sorte! "
      })
    }
     if (location.pathname.includes("resgate-de-ingressos")) {
      setHeading({
        title: "Resgate de Ingressos",
        description: "Além da mecânica de sorteio, você poderá resgatar um combo de ingressos através da Junte & Troque."
      })
    }
    if (location.pathname.includes("ganhadores")) {
      setHeading({
        title: "Ganhadores",
        description: "Acompanhe aqui os sortudos desta promoção"
      })
    }
    if (location.pathname.includes("postos-participantes")) {
      setHeading({
        title: "Postos Participantes",
        description: "Selecione abaixo Estado, Cidade e Bairro para encontrar os postos e lojas mais próximos de você."
      })
    }
    if (location.pathname.includes("duvidas")) {
      setHeading({
        title: "Dúvidas",
      })
    }
    if (location.pathname.includes("regulamento")) {
      setHeading({
        title: "Regulamento",
      })
    }
    if (location.pathname.includes("senha")) {
      setHeading({
        title: "Recuperar Senha",
      })
    }
    if (location.pathname.includes("cadastro")) {
      setHeading({
        title: isAuth ? "Dados Cadastrais" : "Meu Cadastro",
      })
    }
  }

  useEffect(() => {
    handleHeadingInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <PromoHeaderContainer>
      <PromoHeaderNavWrapper>
        <PromoHeaderTitle>grande prêmio de são paulo 2023</PromoHeaderTitle>
          {width < 1280 ? (
              <MenuHamburguerMobile />
          ):(
            <PromoHeaderNav>
              <HeaderNavLink exact url={`${slug}/gp-sp-2023`}>Como Participar</HeaderNavLink>
              {isAuth && (
                <HeaderNavLink url={`${slug}/gp-sp-2023/numeros-da-sorte`}>Meus Números da Sorte</HeaderNavLink>
              )}
              <HeaderNavLink url={`${slug}/gp-sp-2023/resgate-de-ingressos`}>Resgate de Ingressos</HeaderNavLink>
              <HeaderNavLink url={`${slug}/gp-sp-2023/ganhadores`}>Ganhadores</HeaderNavLink>
              <HeaderNavLink url={`${slug}/gp-sp-2023/postos-participantes`}>Postos Participantes</HeaderNavLink>
            </PromoHeaderNav>
          )}
      </PromoHeaderNavWrapper>
      <PromoHeaderContent>
        {location.pathname !== `${slug}/gp-sp-2023` ? (
          <PromoHeaderHeading $isPostosPage={window.location.pathname.includes("postos-participantes")}>
            <h1>{heading.title}</h1>
            {heading.description && (<span>{heading?.description}</span>)}
          </PromoHeaderHeading>
        ) : (
          <>
            <PromoHeaderWebdoor>
              <img
                className="webdoor"
                src={width >= 1280 ? ImageWebdoor : ImageWebdoorMobile}
                draggable="false"
                alt="Sob fundo vermelho, carro de Fórmula-1 da Ferrari aparece posicionado sobre uma letra V, em referência a Shell V-Power"
              />
              <img
                className="shellIcon"
                src={IconShellLarge}
                draggable="false"
                alt="Logo da Shell"
              />
              <PromoHeaderWebdoorLettering>
                <img src={ImageVpower} alt="Logo do combustível Shell V-Power" />
                <h1>V-ocê no Grande Prêmio de São Paulo 2023.</h1>
                <p>Acumule conchas e resgate seu ingresso ou concorra através do sorteio.</p>
              </PromoHeaderWebdoorLettering>
            </PromoHeaderWebdoor>
            {!isAuth && (
              <PromoHeaderWebdoorButtons>
                <PromoActionButton to={`${slug}/gp-sp-2023/cadastro`}>cadastre-se</PromoActionButton>
                <PromoActionLoginButton onClick={handleLoginClick}>faça login</PromoActionLoginButton>
              </PromoHeaderWebdoorButtons>
            )}
          </>
        )}
      </PromoHeaderContent>
    </PromoHeaderContainer>
  )
}