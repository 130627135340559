import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "@chakra-ui/core";
import CarouselWebdoor from "./CarouselWebdoor";
import { getSlug } from "utils/helper";
import { useRouter } from "hooks/useRouter";
import { auth as useAuth } from "hooks/auth";
import { useActions, useStore } from "configureStore";
import { NavigationAnoTodo } from "../../Navigation/NavigationAnoTodo";

//assets
import CardImg from "assets/images/anoTodo/card-item.png";

export function WebdoorAnoTodo() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { auth } = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isShellbox = window.location.search.includes("shellbox");
  const setIsOpen = useActions((action) => action.home.setIsOpen);

  const getShells = useActions((action) => action.anoTodo.getShells);
  const getMe = useActions((action) => action.user.getMe);

  useEffect(() => {
    const _auth = localStorage.getItem("Hub-auth");
    if (window.location.search.includes("shellbox") && !_auth) {
      setLoading(true);
      let count = 0;

      const interval = setInterval(() => {
        const _auth = localStorage.getItem("Hub-auth");
        if (_auth) {
          getMe();
          getShells();
          clearInterval(interval);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          if (count > 7) {
            setLoading(false);
            clearInterval(interval);
            return;
          }
          count++;
        }
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getShells();
      getMe();
    }
  }, [isShellbox, isAuthenticated, getShells, getMe]);

  return (
    <HeaderWrapper>
      <NavigationAnoTodo />

      <CarouselWebdoor />

      {!isAuthenticated && !isShellbox && (
        <ButtonWrapper>
          <Box
            d={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            w={"90%"}
            maxW={["280px", "280px", "280px", "37.25rem"]}
          >
            <Button onClick={() => router.push(`${getSlug().url}/cadastro`)}>
              cadastre-se
            </Button>
            <Button transparent onClick={() => setIsOpen(true)}>
              faça login
            </Button>
          </Box>
        </ButtonWrapper>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${(props) => props.theme.color.shellPurple};
  padding: 8px 0 0;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    padding: 4px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.shellDarkPurple};
  /* margin-top: 1rem; */

  width: 100%;
  height: 54px;

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    height: 95px;
  }
`;

const Button = styled.button`
  width: 8.5rem;
  height: 1.875rem;
  color: ${(props) =>
    props.transparent
      ? props.theme.color.white
      : props.theme.color.shellPurple};
  background: ${(props) =>
    props.transparent ? "rgba(87, 22, 87, 0.9)" : "#FFF"};
  border: 1px solid ${(props) => props.theme.color.grayPurple};
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.688rem;
  font-weight: bold;
  font-family: ${(props) => props.theme.Shell.Bold};
  text-transform: uppercase;
  transition: all 0.2s;

  & + button {
    margin-left: auto;
  }

  &:hover,
  :focus {
    border-color: ${(props) => props.theme.color.yellow};
    color: ${(props) => props.theme.color.shellPurple};
    background-color: ${(props) => props.theme.color.yellow};
  }

  @media (min-width: ${(props) => props.theme.queries.medium}) {
    width: 18rem;
    height: 2.75rem;

    font-size: ${(props) => props.theme.fontSize.text};
  }
`;
