import styled from "styled-components";

export const GanhadoresContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 830px;
  gap: 1.5rem;
  margin: 1.626rem auto;

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin: 2.75rem auto;
  }
`;

export const GanhadoresNoticeBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  width: 90%;
  max-width: 712px;
  padding: 1.25rem 1rem;
  margin: 0 auto;

  background: ${(props) => props.theme.color.lightGray};

  p {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.2;
    color: ${(props) => props.theme.color.text};
    margin: 0;
  }

  span {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.2;
    color: ${(props) => props.theme.color.text};
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin: unset;
    padding,
    span {
      font-size: 1rem;
    }
    /* line-height: 0.6875rem; */
  }
`;

export const GanhadoresContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0.5rem;

  width: 100%;
  max-width: 868px;
  padding: 0 1rem;
  color: ${(props) => props.theme.color.gray};

  p {
    font-family: ${props => props.theme.Shell.Book};
  }
  strong {
    font-family: ${props => props.theme.Shell.Bold};
  }

  @media (min-width: 640px) {
    justify-content: center;
    /* align-items: center; */
    margin: 1.25rem auto;
    max-width: 39.5rem;
    padding: 0;
  }
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    max-width: 868px;
  }
`;

export const GanhadoresTable = styled.table`
  font-family: ${(props) => props.theme.Shell.Heavy};
  color: ${(props) => props.theme.color.text};
  min-width: 540px;
  border-collapse: collapse;
  /* max-width: 540px; */

  margin-top: 2rem;
  thead {
    border-bottom: 2px solid ${(props) => props.theme.color.text};

    tr {
      td,th {
        padding-bottom: 15px;
        
        font-size: ${(props) => props.theme.fontSize.text};
        font-weight: 600;
        line-height: 1.2;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #d3d3d3;
      td {
        padding: 1.25rem 0;
        min-width: 146px;

        font-family: ${(props) => props.theme.FuturaFont};
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 100%;
    max-width: 864px;

    thead {
      tr {
        td,th {
          text-align: center;
          margin: auto;
          font-size: 1.125rem;
        }
      }
    }
    tbody {
      td {
        text-align: center;
      }
    }
  }
`;

export const NumerosDaSorteTabWrapper = styled.div`
  display: flex;
  max-width: 827px;
  gap: 8px;
  margin: 1.25rem 1rem 0 1rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 640px) {
    margin: 1.25rem auto 0;
  }
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin: 2.75rem auto 0;
    width: 100%;
  }
`;
