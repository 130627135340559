import React from "react";
import {
  AccordionWrapper
} from "./styles";
import {
  ContainerIcon,
  CustomAccordionItem,
  CustomAcordion,
  MyAccordionBox,
  MyAccordionHeader,
  MyAccordionIcon,
  MyAccordionPanel
} from "modules/promoGrandeGp/pages/Duvidas/styles";
import { theme } from "theme";

export function VideoTranscription() {
  return (
    <AccordionWrapper>
      <CustomAcordion
        defaultIndex={0}
        width={"100%"}
        maxWidth={"1080px"}
        allowMultiple
      >
        <CustomAccordionItem marginBottom={"30px"} height="100%">
          <MyAccordionHeader>
            <MyAccordionBox flex="1" textAlign="left">
              Transcrição do vídeo
            </MyAccordionBox>
            <ContainerIcon className={"btn-chevron"}>
              <MyAccordionIcon />
            </ContainerIcon>
          </MyAccordionHeader>
          <MyAccordionPanel fontFamily={theme.helvetica}>
            <p>Promoção para assistir o GP de São Paulo</p>

            <p>
              Visão geral da seção: Esta seção apresenta uma promoção para assistir ao Grande Prêmio de São Paulo
              diretamente da arquibancada exclusiva da Shell.
            </p>
            <strong>Promoção para assistir ao GP de São Paulo</strong>
            <ul>
              <li>A Shell está oferecendo uma promoção para que os participantes possam assistir ao GP de São Paulo na arquibancada exclusiva da Shell.</li>
              <li>Para participar, é necessário baixar o aplicativo do Shell Box e selecionar a opção "Participar das promoções" nas configurações de notificações.</li>
              <li>Abasteça com pelo menos R$ 100 em combustível Shelby Power Gasolina, Shelby Power Etanol ou Shelby Power Racing para ganhar um número da sorte.</li>
              <li>Com esse número, você concorre a um par de ingressos para assistir ao GP diretamente da arquibancada exclusiva da Shell.</li>
              <li>A promoção começa no dia 15 de agosto e os sorteios ocorrerão todos os sábados entre 9 e 30 de setembro.</li>
              <li>É importante ficar atento aos períodos de participação, datas dos sorteios e regulamento.</li>
            </ul>
            <p>
              Para acompanhar seus números da sorte ou verificar os ganhadores, basta acessar as abas correspondentes
              no menu do aplicativo do Shell Box.
            </p>
            <strong>00:24 Participação na promoção</strong>

            <p>Visão geral da seção: Nesta seção, são fornecidas informações sobre como participar da promoção.</p>

            <strong>Participação na promoção</strong>

            <ul>
              <li>
                Para participar da promoção, é necessário abastecer com pelo menos R$ 100 em combustível Shelby Power
                Gasolina, Shelby Power Etanol ou Shelby Power Racing.
              </li>

              <li>
                Ao fazer isso, você receberá um número da sorte para concorrer ao par de ingressos para assistir ao GP
                de São Paulo na arquibancada exclusiva da Shell.
              </li>
            </ul>

            <strong>00:44 Sorteios e informações adicionais</strong>

            <p>Visão geral da seção: Nesta seção, são fornecidas informações sobre os sorteios e outras informações relevantes.</p>

            <strong>Sorteios e informações adicionais</strong>

            <ul>
              <li>Os sorteios ocorrerão todos os sábados entre 9 e 30 de setembro.</li>

              <li>
                É importante ficar atento aos períodos de participação, datas dos sorteios e
                regulamento para garantir a participação correta na promoção.
              </li>
            </ul>

            <p>Para acompanhar seus números da sorte ou verificar os ganhadores, basta acessar as abas correspondentes no menu do aplicativo do Shell Box. </p>

            <p>00;00;00;05 - 00;00;02;22 <br />
              Você já imaginou assistir o GP de São Paulo</p>

            <p>00;00;02;22 - 00;00;05;01 <br />
              direto da arquibancada exclusiva da Shell?</p>
            <p>00;00;05;01 - 00;00;06;09 <br />
              Não?!</p>

            <p>00;00;06;09 - 00;00;08;01 <br />
              Então pode imaginar!</p>
            <p>00;00;08;01 - 00;00;09;03 <br />
              Chegou a promoção</p>

            <p>00;00;09;03 - 00;00;12;20 <br />
              Você no Grande Prêmio de São Paulo 2023.</p>

            <p>00;00;12;20 - 00;00;14;09 <br />
              Viva a sensação de carro novo</p>

            <p>00;00;14;09 - 00;00;16;24 <br />
              e a emoção das pistas.</p>

            <p>00;00;16;24 - 00;00;18;23 <br />
              Para participar é fácil,</p>

            <p>00;00;18;23 - 00;00;20;24 <br />
              baixe o aplicativo do Shell Box,</p>

            <p>00;00;20;24 - 00;00;22;15 <br />
              depois vá na aba “menu”</p>

            <p>00;00;22;15 - 00;00;24;07 <br />
              “Ajustes de Notificações”</p>

            <p>00;00;24;07 - 00;00;26;20 <br />
              e selecione “participar das promoções”.</p>

            <p>00;00;26;20 - 00;00;28;11 <br />
              Abasteça a partir de 100 reais</p>

            <p>00;00;28;11 - 00;00;30;03 <br />
              com Shell V-Power Gasolina.</p>

            <p>00;00;30;03 - 00;00;31;14 <br />
              Shell V-Power Etanol</p>

            <p>00;00;31;14 - 00;00;33;01 <br />
              e/ou Shell V-Power Racing</p>

            <p>00;00;33;01 - 00;00;34;23 <br />
              e ganhe um número da sorte.</p>

            <p>00;00;34;23 - 00;00;35;22 <br />
              Com esse número</p>

            <p>00;00;35;22 - 00;00;37;14 <br />
              você concorre a um par de ingressos</p>

            <p>00;00;37;14 - 00;00;38;21 <br />
              para assistir o GP</p>

            <p>00;00;38;21 - 00;00;41;22 <br />
              direto da arquibancada exclusiva da Shell.</p>


            <p>00;00;41;22 - 00;00;44;10 <br />
              A promoção começa dia 15 de agosto</p>

            <p>00;00;44;10 - 00;00;45;22 <br />
              e os sorteios ocorrerão</p>

            <p>00;00;45;22 - 00;00;48;04 <br />
              entre 9 de setembro e 4 de outubro.</p>

            <p>00;00;48;26 - 00;00;51;05 <br />
              Fique atento aos períodos de participação,</p>

            <p>00;00;51;05 - 00;00;54;10 <br />
              as datas dos sorteios e o regulamento.</p>

            <p> 00;00;54;10 - 00;00;56;00 <br />
              Para acompanhar seus números da sorte</p>

            <p>00;00;56;00 - 00;00;57;14 <br />
              ou verificar os ganhadores.</p>

            <p>00;00;57;14 - 00;00;59;19 <br />
              É só clicar nas abas do menu.</p>

            <p>00;00;59;19 - 00;01;01;13 <br />
              Tá esperando o que para acelerar</p>

            <p>00;01;01;13 - 00;01;02;17 <br />
              até a um posto Shell?</p>

            <p>00;01;02;17 - 00;01;06;08 <br />
              Você no Grande Prêmio de São Paulo 2023.</p>

            <p>00;01;06;08 - 00;01;10;01 <br />
              Viva a sensação de carro novo e a emoção das pistas.</p>

            <p>01:11[Música] <br />
              Mnemonic-5-Shell-Piano-Quiet-2-5-sec.wav</p>
          </MyAccordionPanel>
        </CustomAccordionItem>
      </CustomAcordion>
    </AccordionWrapper >
  )
}