import React, { useState } from "react";
import {
  GanhadoresContainer,
  GanhadoresContent,
  // GanhadoresNoticeBlock,
  NumerosDaSorteTabWrapper,
} from "./styles";
import { NumerosDaSorteTab } from "../NumerosDaSorte/styles";
import { GanhadoresTable } from "./GanhadoresTable";
// data
import ganhadores09092023 from "json/ganhadores-gp/ganhadores-09-09-2023.json"
import ganhadores16092023 from "json/ganhadores-gp/ganhadores-16-09-2023.json"
import ganhadores23092023 from "json/ganhadores-gp/ganhadores-23-09-2023.json"
import ganhadores04102023 from "json/ganhadores-gp/ganhadores-04-10-2023.json"

export default function GanhadoresGp() {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <GanhadoresContainer>
      {/* <GanhadoresNoticeBlock>
        <p>
          Atenção! O próximo sorteio da Loteria Federal será realizado em
          04/10/2023.
        </p>
      </GanhadoresNoticeBlock> */}
      <NumerosDaSorteTabWrapper>
        <NumerosDaSorteTab
          aria-selected={currentTab === 0}
          onClick={() => setCurrentTab(0)}
        >
          <span>Sorteio</span>
          <span>Dia 09/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 1}
          onClick={() => setCurrentTab(1)}
        >
          <span>Sorteio</span>
          <span>Dia 16/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 2}
          onClick={() => setCurrentTab(2)}
        >
          <span>Sorteio</span>
          <span>Dia 23/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 3}
          onClick={() => setCurrentTab(3)}
        >
          <span>Sorteio</span>
          <span>Dia 04/10/2023</span>
        </NumerosDaSorteTab>
      </NumerosDaSorteTabWrapper>
      <GanhadoresContent>
        {currentTab === 0 && (
          <>
            <strong>
              Estamos entrando em contato com os possíveis ganhadores desde o
              dia 13 de setembro.
            </strong>
            <p>
              Lembrando que são válidos para este sorteio apenas os números
              adquiridos entre 15/08/2023 e 07/09/2023.
            </p>
            <p>
              Fique atento ao contato pelo telefone e email, pode ser nossa {" "}
              <strong>Central de Atendimento da Promoção</strong> querendo
              falar com você. <br />
              Após o processo de validação da participação, os ganhadores
              oficiais serão divulgados por aqui. Agradecemos sua participação!
            </p>
            <GanhadoresTable tableData={ganhadores09092023} />
          </>
        )}
        {currentTab === 1 && (
          <>
            <strong>
              Estamos entrando em contato com os possíveis ganhadores desde o
              dia 22 de setembro.
            </strong>
            <p>
              Lembrando que são válidos para este sorteio apenas os números
              adquiridos entre 08/09/2023 e 14/09/2023.
            </p>
            <p>
              Fique atento ao contato pelo telefone e email, pode ser nossa {" "}
              <strong>Central de Atendimento da Promoção</strong> querendo
              falar com você. <br />
              Após o processo de validação da participação, os ganhadores
              oficiais serão divulgados por aqui. Agradecemos sua participação!
            </p>
            <GanhadoresTable tableData={ganhadores16092023} />
          </>
        )}
        {currentTab === 2 && (
          <>
            <strong>
              Estamos entrando em contato com os possíveis ganhadores desde o
              dia 26 de setembro.
            </strong>
            <p>
              Lembrando que são válidos para este sorteio apenas os números
              adquiridos entre 15/09/2023 e 21/09/2023.
            </p>
            <p>
              Fique atento ao contato pelo telefone e email, pode ser nossa {" "}
              <strong>Central de Atendimento da Promoção</strong> querendo
              falar com você. <br />
              Após o processo de validação da participação, os ganhadores
              oficiais serão divulgados por aqui. Agradecemos sua participação!
            </p>
            <GanhadoresTable tableData={ganhadores23092023} />
          </>
        )}
        {currentTab === 3 && (
          <>
            <p>
              Lembrando que são válidos para este sorteio apenas os números
              adquiridos entre 22/09/2023 e 30/09/2023.
            </p>
            <p>
              Fique atento ao contato pelo telefone e email, pode ser nossa {" "}
              <strong>Central de Atendimento da Promoção</strong> querendo falar com você. <br />
              Após o processo de validação da participação, os ganhadores oficiais serão divulgados por aqui. Agradecemos sua participação!
            </p>
            <GanhadoresTable tableData={ganhadores04102023} />
          </>
        )}
      </GanhadoresContent>
    </GanhadoresContainer>
  );
}
