import { Box } from "@chakra-ui/core";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  bgColor: string;
  position?: "left" | "center";
  bgImage?: string;
}

export function HeaderBlock({ children, bgColor, position, bgImage }: Props) {
  return (
    <HeaderBlockWrapper
      bgColor={bgColor}
      tabIndex={0}
    >
      <Box
        d={"flex"}
        justifyContent={position === "left" ? "flex-start" : "center"}
        alignItems={position === "left" ? "flex-start" : "center"}
        flexDir={"column"}
        maxW={["18rem","18rem","18rem","16rem"]}
        w={"100%"}
        backgroundImage={`url(${bgImage})`}
        backgroundPosition={["110% 100%","110% 100%","110% 100%","125% 130%"]}
        backgroundRepeat={"no-repeat"}
        bgSize={["73%","73%","73%","78%"]}
      >
        {children}
      </Box>
    </HeaderBlockWrapper>
  )
}

const HeaderBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 1rem;
  background-color: ${props => props.bgColor};

  span {   
    &.spaced {
      letter-spacing: 7pt;
    }
    &.varY {
      color: ${props => props.theme.color.yellow};
    }
  }

  h2 {
    font-family: ${props => props.theme.Shell.Book};
    text-transform: uppercase;
    color: #fff;
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 110%;
    margin: 4px 0 6px;
    white-space: nowrap;
    
    &.spaced {
      letter-spacing: 7pt;
    }
    &.varY {
      color: ${props => props.theme.color.yellow};
    }
  }

  p {    
    &.varY {
      color: ${props => props.theme.color.yellow};
    }
    &.sm {
      font-size: 1.125rem;
    }
    &.md {
      font-size: 1.5rem;
    }
    b {
      color: ${props => props.theme.color.yellow};
    }
    span.plus {
      display: inline-block;
      font-size: 2.5rem;
    }
  }
  
  strong {
    display: block;
    font-size: 2.5rem;
    line-height: 90%;
    font-family: ${props => props.theme.Shell.Bold};
    text-transform: uppercase;
    color: ${props => props.theme.color.yellow};
    box-sizing: border-box;

    &.xl {
      font-size: 4rem;
    }
  }

  img {
    width: 3rem;
  }

  @media (min-width: ${props => props.theme.queries.lg}) {
    justify-content: flex-end;
    align-items: flex-end;
    
    h2 {
      /* font-size: ${props => props.theme.fontSize.text}; */
      font-size: 1rem;
    }
    img {
      width: 2.5rem;
    }
    p {
      &.md {
        font-size: 1.375rem;
      }
      span.plus {
        font-size: 2rem;
      }
    }
    strong {
      font-size: 2.125rem;

      &.xl {
        font-size: 3.5rem;
      }
    }
  }
`;