import { useActions, useStore } from "configureStore";
import { useEffect, useState } from "react";
import { GetUrlShop } from "services/shell";
import Cookies from "js-cookie"

type ProductSSO = {
  productId: number;
  isRedirectReady: boolean;
}

type SSOResponse = {
  data: {
    token: string
    redirectURL: string
    expiresIn?: number
    expiresOn?: number
  }
  productId: any
}

export function useSSO() {
  const [productSSO, setProductSSO] = useState<ProductSSO>(null);
  const [responseSSO, setResponseSSO] = useState<SSOResponse>(null);
  const [sessionStorageKey, setSessionStorageKey] = useState("")
  const [formId, setFormId] = useState("")
  const buttonClickCount = useStore((state) => state.anoTodo.buttonClickCount)
  const setButtonClickCount = useActions((action) => action.anoTodo.setButtonClickCount);
  const setActive = useActions(action => action.loading.setActive);
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const isAuth = useStore((state) => state.auth.isAuthenticated)
  const isShellbox = window.location.search.includes("shellbox")

  /**
   * Busca o valor da chave SSO na session storage e armazena em um estado
   * @param sessionKey O nome da chave na session storage
   */
  async function getStorageProductSSO(sessionKey: string) {
    try {
      const _productSSO = await JSON.parse(sessionStorage.getItem(sessionKey));
      setProductSSO({
        productId: _productSSO?.productId,
        isRedirectReady: _productSSO?.isRedirectReady
      })
    } catch (error) {
      console.error(error);
      setButtonClickCount(1)
    }
  }

  /**
   * Função que prepara a chamada para o SSO
   * @param productId O id do produto que será enviado ao SSO
   * @param sessionKey A chave da session storage a ser armazenada
   * @param formSSOElementId O id do formulário que será ativado para o redirecionamento ao SSO
   */
  function handleRedeemSelectedProduct(productId: number, sessionKey: string, formSSOElementId: string) {
    // if (!isShellbox) {
    //   setIsOpen(true)
    //   return
    // }
    try {
      if (buttonClickCount > 0) {
        setFormId(formSSOElementId)
        setSessionStorageKey(sessionKey)
        setButtonClickCount(0);
        sessionStorage.setItem(sessionKey, JSON.stringify({ productId, isRedirectReady: true }));
        getStorageProductSSO(sessionKey);
      }
    } catch (err) {
      console.error(err)
      setButtonClickCount(1)
    }
  }

  async function getSSO(productId: number) {
    const cookieTokenName = 'shell-sso-token'
    const cookieURLName = 'shell-sso-redirectURL'

    try {
      const cookieToken = Cookies.get(cookieTokenName)
      const cookieURL = Cookies.get(cookieURLName)

      if (
        (cookieToken !== "undefined" && cookieToken !== "" && Boolean(!!cookieToken)) &&
        (cookieURL !== "undefined" && cookieURL !== "" && Boolean(!!cookieURL))
      ) {
        console.log("Retrieving cookies...")
        setResponseSSO({
          data: {
            token: cookieToken,
            redirectURL: cookieURL
          },
          productId
        })
      } else {
        const res = await GetUrlShop();
        const response: SSOResponse = res.data

        if (res?.data?.success) {
          console.log("Creating cookies...")
          Cookies.set(cookieTokenName, response?.data?.token, { expires: (response.data.expiresIn / 60) / 1440 })
          Cookies.set(cookieURLName, response?.data?.redirectURL, { expires: (response.data.expiresIn / 60) / 1440 })
          setResponseSSO({
            data: response.data,
            productId
          })
        }
      }
    } catch (err) {
      console.error(err);
      setButtonClickCount(1);
    }
  }

  function handleWebviewExternalLink(url: string) {
    if (isShellbox) {
      try {
        // @ts-ignore
        WebViewJavascriptInterface.openExternalLink(url);
      } catch (_) {
        window.open(url, "_blank", "noopener noreferrer");
        window.focus();
      }
      setActive(true);
    }
  }

  useEffect(() => {
    if (isAuth && productSSO && productSSO?.isRedirectReady) {
      setActive(true);
      getSSO(productSSO?.productId);
      // handleWebviewExternalLink(responseSSO?.data.redirectUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSSO, isAuth])

  useEffect(() => {
    try {
      const form = document.getElementById(formId) as HTMLFormElement;
      if (
        isAuth &&
        responseSSO?.data?.redirectURL &&
        responseSSO?.data?.token &&
        productSSO?.isRedirectReady
      ) {
        console.log('Form trigger')
        form.submit();
        sessionStorage.removeItem(sessionStorageKey);
      } else {
        sessionStorage.removeItem(sessionStorageKey);
        setButtonClickCount(1)
        throw new Error('Failed to redirect')
      }
    } catch (err) {
      console.error(`SSO redirection error: ${err}`)
      sessionStorage.removeItem(sessionStorageKey);
      setButtonClickCount(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseSSO]);

  return {
    productSSO,
    responseSSO,
    handleRedeemSelectedProduct,
    getStorageProductSSO
  }
}
