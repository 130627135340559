import React, { useEffect, useState } from "react"
import {
  NumerosDaSorteContainer,
  NumerosDaSorteContent,
  NumerosDaSorteTab,
  NumerosDaSorteTabWrapper,
  Ticket,
  TicketBody,
  TicketDate,
  TicketHeader,
  TicketLocation,
  TicketNumber
} from "./styles"
import useWindowSize from "hooks/useWIndowSize";
import { useActions, useStore } from "configureStore";
import { Box, Spinner } from "@chakra-ui/core";
import { theme } from "theme";

export default function NumerosDaSorteGp() {
  const [currentTab, setCurrentTab] = useState(0);
  const [promoInfoText, setPromoInfoText] = useState<string[]>([])
  const ticketList = useStore((state) => state.grandeGpSp.filteredTickets)
  const isLoading = useStore((state) => state.grandeGpSp.isTicketLoading)
  const getAllTickets = useActions((action) => action.grandeGpSp.getAllTickets)
  const setTicketsByDate = useActions((action) => action.grandeGpSp.setTicketsByDate)
  const { width } = useWindowSize()
  const isMobile = width <= 768

  function setDateRange(firstDate: string, secondDate: string, selectedTab: number) {
    const firstDateParsed = `${firstDate.split("-")[2]}-${firstDate.split("-")[1]}-${firstDate.split("-")[0]}`
    const secondDateParsed = `${secondDate.split("-")[2]}-${secondDate.split("-")[1]}-${secondDate.split("-")[0]}`

    setTicketsByDate({
      firstDate: firstDateParsed,
      secondDate: secondDateParsed
    })
    setCurrentTab(selectedTab)
  }

  function formatTicket(code: string) {
    const digitsBeforeDot = code.substring(0, 2)
    const digitsAfterDot = code.substring(2, code.length)
    return `${digitsBeforeDot}.${digitsAfterDot}`
  }

  useEffect(() => {
    getAllTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        setPromoInfoText([
          "São válidos para este sorteio apenas os números adquiridos no período:",
          "15/08/2023 a 07/09/2023",
          "(limitado a cinco números da sorte por dia)"
        ])
        break;
      case 1:
        setPromoInfoText([
          "São válidos para este sorteio apenas os números adquiridos no período:",
          "08/09/2023 a 14/09/2023",
          "(limitado a cinco números da sorte por dia)"
        ])
        break;
      case 2:
        setPromoInfoText([
          "São válidos para este sorteio apenas os números adquiridos no período:",
          "15/09/2023 a 21/09/2023",
          "(limitado a cinco números da sorte por dia)"
        ])
        break;
      case 3:
        setPromoInfoText([
          "São válidos para este sorteio apenas os números adquiridos no período:",
          "22/09/2023 a 30/09/2023",
          "(limitado a cinco números da sorte por dia)"
        ])
        break;
      default:
        setPromoInfoText([
          "São válidos para este sorteio apenas os números adquiridos no período:",
          "15/08/2023 a 07/09/2023",
          "(limitado a cinco números da sorte por dia)"
        ])
        break;
    }
  }, [currentTab]);

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"2rem"}
      >
        <Spinner
          size={"xl"}
          margin={"0 auto"}
          emptyColor={theme.color.lightGray200}
          thickness="4px"
          color={theme.color.red}
        />
      </Box>
    )
  }

  return (
    <NumerosDaSorteContainer>
      <NumerosDaSorteTabWrapper>
        <NumerosDaSorteTab
          aria-selected={currentTab === 0}
          onClick={() => setDateRange("15-08-2023", "07-09-2023", 0)}
        >
          <span>Sorteio</span>
          <span>Dia 09/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 1}
          onClick={() => setDateRange("08-09-2023", "14-09-2023", 1)}
        >
          <span>Sorteio</span>
          <span>Dia 16/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 2}
          onClick={() => setDateRange("15-09-2023", "21-09-2023", 2)}
        >
          <span>Sorteio</span>
          <span>Dia 23/09/2023</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 3}
          onClick={() => setDateRange("22-09-2023", "30-09-2023", 3)}
        >
          <span>Sorteio</span>
          <span>Dia 04/10/2023</span>
        </NumerosDaSorteTab>
      </NumerosDaSorteTabWrapper >
      <NumerosDaSorteContent>
        <div>
          {promoInfoText.map((line) => <p key={`${line}#${currentTab}`}>{line}</p>)}
        </div>
        {Boolean(ticketList?.length > 0 && !isLoading) && ticketList?.map((ticket) => {
          return ticket.promotionalCodes.map((code) => (
            <Ticket tabIndex={0} key={code}>
              <TicketHeader>
                <TicketNumber>
                  <span>Número</span>
                  <strong>{formatTicket(code)}</strong>
                </TicketNumber>
                {isMobile && (
                  <TicketDate>
                    <strong>Data de aquisição</strong>
                    <span>{ticket?.transactionDate}</span>
                  </TicketDate>
                )}
              </TicketHeader>
              <TicketBody>
                {!isMobile && (
                  <TicketDate>
                    <strong>Data de aquisição</strong>
                    <span>{ticket?.transactionDate}</span>
                  </TicketDate>
                )}
                <TicketLocation>
                  <strong>Local</strong>
                  <span>{ticket?.merchantName}</span>
                </TicketLocation>
              </TicketBody>
            </Ticket>
          ))
        })}
        {Boolean(ticketList?.length < 1 && !isLoading) && (
          <strong>Nenhum número da sorte disponível.</strong>
        )}
      </NumerosDaSorteContent>
    </NumerosDaSorteContainer >
  )
}