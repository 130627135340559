import React from "react";
import styled from "styled-components";
import { useActions } from "configureStore";
import { Box } from "@chakra-ui/core";
import { ButtonQuero } from "./Home/SectionYellowCards";
import useWindowSize from "hooks/useWIndowSize";
import { useRouter } from "hooks/useRouter";
import { getSlug } from "utils/helper";
import { LinkAccessibility } from "utils/linkAcessibility";

export default function FooterAnoTodo() {
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const { width } = useWindowSize();
  const router = useRouter();
  const isHome = window.location.pathname.includes("todos-produtos");

  return (
    <FooterContainer isHome={isHome}>
      <Box
        d={"flex"}
        flexDir={["column", "column", "column", "row"]}
        justifyContent={"center"}
        maxW={"1280px"}
        w={"95%"}
      >
        <Content>
          <h3>Já sou cadastrado na campanha ou no app Shell Box</h3>
          <p>
            Participei de outras campanhas ou já tenho cadastro no App Shell Box e aceitei participar das promoções.
          </p>
          <ButtonFooter
            onClick={() => setIsOpen(true)}
          >
            entrar
          </ButtonFooter>
        </Content>

        <Separator />

        <Content>
          <h3>Ainda não sou <br /> cadastrado</h3>
          <Box>
            <p>
              Ainda não tenho cadastro e quero participar da campanha.
            </p>
            <p>
              Não se esqueça também de {width < 1024 && <br />}
              {/* <a href="">baixar o app Shell Box</a>. */}{" "}
              <LinkAccessibility
                href="https://shellbox.onelink.me/RT9I/49c873d1"
                isNewTab
              >
                baixar o app Shell Box
              </LinkAccessibility>.
            </p>
          </Box>
          <ButtonFooter
            onClick={() => router.push(`${getSlug().url}/cadastro`)}
          >
            cadastre-se
          </ButtonFooter>
        </Content>
      </Box>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${props => props.theme.color.lightYellow50};
  margin-top: ${props => props.isHome && "1.25rem"};
  transition: all 0.2s;

  &:hover {
    background: ${props => props.theme.color.yellow};
    button {
      background: ${props => props.theme.color.shellPurple};
      color: ${props => props.theme.color.yellow};
    }
  }
  
  @media (min-width: ${(props) => props.theme.queries.lg}) {
    flex-direction: row;
    margin-top: ${props => props.isHome && "3.25rem"};
  }
`;

const Content = styled.div`
  display: grid;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;

  width: 95%;
  max-width: 260px;
  padding: 1.5rem 0;

  margin: 0 auto;
  color: ${props => props.theme.color.text};

  h3 {
    font-family: ${props => props.theme.Shell.Bold};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: 1.25rem;
    margin: 0;
    margin-bottom: 10px;

    br {
      display: none;
    }
  }
  p {
    font-family: ${props => props.theme.helvetica};
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 130%;
    margin: 0;

    & + p {
      margin-top: 12px;
    }

    a {
      color: ${props => props.theme.color.shellPurple};
    }
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    justify-content: flex-start;
    max-width: 380px;
    margin-top: 24px;
    grid-template-rows: 3.5rem auto 1fr;

    h3 {
      font-size: 1.125rem;
      line-height: 1.25rem;
      margin-bottom: 1rem;
      br {
        display: block;
      }
    }
    p {
      font-size: 0.875rem;
      line-height: 1.063rem;
      & + p {
        margin-top: 0;
      }
    }
  }
`;

const Separator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.lightGray};

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    width: 1px;
    height: 17.25rem;
  }
`;

const ButtonFooter = styled(ButtonQuero)`
  background: ${props => props.theme.color.yellow};
  border-color: ${props => props.theme.color.yellow};
  color: ${props => props.theme.color.shellPurple};
  
  margin-top: 1rem;
  
  &:hover, :focus {
    background: ${props => props.theme.color.shellPurple};
    color: ${props => props.theme.color.yellow};
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    margin-top: 2.25rem;
  }
`;