import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import Smooch from "smooch";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { get, isEmpty } from "lodash";
import queryString from "query-string";
import { auth as useAuth } from "hooks/auth";
import Navbar from "modules/common/Navbar/Navbar";
import NavbarSmall from "modules/common/Navbar/NavbarSmall";
import Footer from "../Footer";
import { useTracking } from "hooks/useTracking";
import { useActions, useStore } from "configureStore";
import useWindowSize from "hooks/useWIndowSize";
import LoadingOverlay from "react-loading-overlay";
import { getSlug } from "utils/helper";
import ModalPromoWarning from "modules/common/ModalPromoWarning";
import ShellboxBottomMenu from "modules/common/ShellboxBottomMenu";
import { Footer as FooterNew } from "modules/common/NewFooter/index"
import { SmartNavbar } from "./SmartNavbar";

const Layout = (props) => {
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(props.location.search);
  const queryShellBox = (query.shellbox as string) || "";
  const { width } = useWindowSize();
  const slug = getSlug().url

  const { auth, cachedAuth } = useAuth(true);

  const getMe = useActions((actions) => actions.user.getMe);
  const user = useStore((store) => store.user.user);

  const setActive = useActions((actions) => actions.loading.setActive);
  const isActive = useStore((actions) => actions.loading.isActive);

  const isAuth =
    (!!get(auth, "token", false) || !!get(cachedAuth, "token", false)) &&
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register") &&
    !window.location.pathname.includes("resetPassword");

  useEffect(() => {
    if (isAuth && (!user || isEmpty(user))) {
      getMe();
    }
    // if (window.location.pathname === "/") {
    //   history.push(getSlug().url);
    // }

    if (!isAuth && !window.location.search.includes("shellbox")) {
      if (typeof Smooch.destroy === "function") Smooch.destroy();
    }
  }, [getMe, history, isAuth, user]);

  useTracking();

  useEffect(() => {
    if (
      localStorage.getItem("query_shell")?.length === 0 ||
      localStorage.getItem("query_shell") !== queryShellBox
    ) {
      localStorage.setItem("query_shell", queryShellBox);
    }

    if (queryShellBox?.length > 0) {
      localStorage.setItem("query_shell", queryShellBox);
    }
  }, [queryShellBox]);

  const changeGrid = () => {
    return (
      <GridContentWithoutPadding>{props.children}</GridContentWithoutPadding>
    );
  };

  // const changeNavbar = (pathname) => {
  //   switch (pathname) {
  //     case "/" ||
  //       "/promocoes-de-rodovia" ||
  //       "/ofertas-shell-select" ||
  //       "/ofertas-shell-select/detalhe" ||
  //       "/campanhas-de-lubrificantes" ||
  //       "/beneficios-app-shell-box":
  //       return <Navbar auth={auth.isAuthenticated} />;
  //     case `${slug}/todos-produtos` ||
  //       `${slug}/postos-participantes` ||
  //       `${slug}/como-funciona` ||
  //       `${slug}/cadastro` ||
  //       `${slug}/duvidas` ||
  //       `${slug}/regulamento`:
  //       return (
  //         <>
  //           <NavbarSmall auth={auth.isAuthenticated} />
  //           <ShellboxBottomMenu />
  //         </>
  //       );
  //     case `${slug}/minhas-conchas` ||
  //       `${slug}/meus-resgates` ||
  //       "/notificacoes":
  //       return <NavbarSmall auth={auth.isAuthenticated} />;
  //     default:
  //       return <NavbarSmall auth={auth.isAuthenticated} />;
  //   }
  // };

  

  const scrollToComponent = (hash) => {
    if (hash) {
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isActive) {
      setTimeout(() => setActive(false), 5000);
    }
  }, [isActive, setActive]);

  return (
    <Fragment>
      <LoadingOverlay
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            position: isActive ? "fixed" : "initial",
            top: 0,
            left: 0,
            "z-index": 20,
          },
        }}
        active={isActive}
        spinner
        text="Carregando..."
      ></LoadingOverlay>
      <SkipToContent
        tabIndex={0}
        onClick={() => scrollToComponent("como-participar")}
      >
        Ir para conteúdo
      </SkipToContent>
      <Grid>
        <GridHeader>
          {/* {window.location.pathname && changeNavbar(window.location.pathname)} */}
          <SmartNavbar />
        </GridHeader>
        {window.location.pathname && changeGrid()}
        {!window.location.pathname.includes("gp-sp-2023")
          ? <Footer />
          : <FooterNew />
        }
      </Grid>
    </Fragment>
  );
};

export default withRouter(Layout);

const SkipToContent = styled.button`
  cursor: pointer;
  z-index: 99999;
  background: ${(props) => props.theme.color.yellow};
  text-decoration: none;
  color: ${(props) => props.theme.color.darkGray};
  outline-color: ${(props) => props.theme.color.red};
  min-height: 50px;
  padding: 1rem;
  left: 45%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    transform: translateY(1%);
  }
`;

const Grid = styled.main`
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
`;

const GridHeader = styled.div`
  height: 100%;
`;

const GridContent = styled.div`
  max-width: 1400px;
  margin: 0rem auto 0 auto;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  grid-area: content;
  padding: 0 2em;
  /* min-height: 100vh; */
  @media (max-width: 1200px) {
    padding: 0 1em;
  }
`;

const GridContentWithoutPadding = styled(GridContent)`
  background-color: ${(props) => props.bgColor || "#fff"};
  max-width: 100% !important;
  padding: 0 !important;
  @media (max-width: 1200px) {
    padding: 0 !important;
  }
`;
