/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import { auth as useAuth } from "hooks/auth";
import { useActions, useStore } from "configureStore";
import ConchaShell from "assets/icons/concha-shell.svg";
import GridLoginOrSignup from "modules/promocaoJunteTroque/PromotionHub/LoginOrSignup/GridLoginOrSignup";
import Arrow from "assets/icons/arrow-footer.svg";
import ModalInfoMobile from "modules/common/ModalInfoMobile";
import ModalInfo from "modules/common/ModalInfo";
import { Box, Spinner, useDisclosure } from "@chakra-ui/core";
import { getSlug } from "utils/helper";
import { VscLinkExternal } from "react-icons/vsc";
import useWindowSize from "hooks/useWIndowSize";
import useShellbox from "hooks/useShellbox";
import { GetUrlShop } from "services/shell";
import { IconNewWindow } from "modules/common/IconNewWindow";
import { LinkAccessibility } from "utils/linkAcessibility";

type ProductSSO = {
  productId: number;
  isRedirectReady: boolean;
};

const Footer = () => {
  const isLoading = useStore((state) => state.shellboxBenefits.isLoading);
  const benefitsContent = useStore((state) => state.shellboxBenefits.content);
  const setActive = useActions((actions) => actions.loading.setActive);
  const { width } = useWindowSize();
  const isShellbox = useShellbox();
  const [item, setItem] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const isPromoFooter = useStore((state) => state.anoTodo.isPromoFooter);
  const getPromoFooter = useActions(
    (action) => action.anoTodo.getIsPromoFooter
  );
  const setIsOpen = useActions((action) => action.home.setIsOpen);
  const isGrandeGp = window.location.pathname.includes("gp-sp-2023")
  const [productSSO, setProductSSO] = useState<ProductSSO>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { auth, cachedAuth } = useAuth(false);

  const isAuthenticated =
    (!!get(auth, "token", false) || !!get(cachedAuth, "token", false)) &&
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register") &&
    !window.location.pathname.includes("resetPassword");

  useEffect(() => {
    getStorageProductSSO(); // Pega valor na sessionStorage e armazena no estado
  }, []);

  useEffect(() => {
    if (item?.redirectURL) {
      const form = document.getElementById("formFooter") as any;
      form.submit();
      sessionStorage.removeItem("productSSO");
    }
  }, [item]);

  useEffect(() => {
    if (isAuthenticated && productSSO && productSSO.isRedirectReady) {
      setActive(true);
      getSSO(productSSO?.productId);
    } else if (productSSO?.productId) {
      setIsOpen(true);
    }
  }, [isAuthenticated, productSSO, setActive, setIsOpen]);

  function handleRedeemSelectedProduct(productId: number) {
    // Ao clicar no botão armazena o estado na sessionStorage
    sessionStorage.setItem(
      "productSSO",
      JSON.stringify({ productId, isRedirectReady: true })
    );
    getStorageProductSSO();
  }

  async function getSSO(productId: number) {
    try {
      const res = await GetUrlShop();
      if (res.data) {
        setItem({
          ...res.data.data,
          productId,
        });
      }
    } catch (err) {
      // console.error(err);
    }
  }

  async function getStorageProductSSO() {
    try {
      const _productSSO = await JSON.parse(
        sessionStorage.getItem("productSSO")
      );
      setProductSSO({
        productId: _productSSO?.productId,
        isRedirectReady: _productSSO?.isRedirectReady,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleLink() {
    try {
      const res = await GetUrlShop();
      if (res.data) {
        setItem({
          ...res.data.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleExternalLink(url: string, isWhatsApp?: boolean) {
    if (isWhatsApp) {
      const wppLink = url
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
      return (
        <a
          aria-label={`Número whatsapp: ${url}`}
          href={`https://wa.me/55${wppLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      );
    } else {
      return (
        <a
          aria-label={`${url} (abre em uma nova aba)`}
          href={`https://${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      );
    }
  }

  return (
    <Wrapper>
      <ModalInfo
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={"Estamos arrumando a casa!"}
        description={
          "O nosso sistema está passando por uma atualização. Em breve, você poderá resgatar o seu produto normalmente. Você poderia voltar mais tarde? :)"
        }
        type="modalBannerShell"
      />
      <ModalInfoMobile
        isOpen={isOpen}
        onClose={onClose}
        title={"Estamos arrumando a casa!"}
        description={
          "O nosso sistema está passando por uma atualização. Em breve, você poderá resgatar o seu produto normalmente. Você poderia voltar mais tarde? :)"
        }
        type="modalBannerShell"
      />
      {/* {window &&
        window.location.pathname === getSlug().url &&
        window.location.pathname !== "/" ? (
        <GridLoginOrSignup />
      ) : null} */}
      {(!isShellbox ||
        window.location.pathname === `${getSlug().url}` ||
        window.location.pathname.includes("como-funciona")) && (
          <>
            <FirstLine>
              <FirstColumn>
                {window.location.search.includes("shellbox") ? (
                  <PrivacyText
                    onClick={() => {
                      try {
                        // @ts-ignore
                        WebViewJavascriptInterface.openExternalLink(
                          "https://www.shell.com.br/termos-e-condicoes.html"
                        );
                      } catch (_) {
                        window.open(
                          "https://www.shell.com.br/termos-e-condicoes.html",
                          "_blank",
                          "noopener"
                        );
                        window.focus();
                      }
                      setActive(true);
                    }}
                  >
                    <MyAnchor className="termos-de-uso">Termos de Uso</MyAnchor>
                  </PrivacyText>
                ) : (
                  <PrivacyText
                    href="https://www.shell.com.br/termos-e-condicoes.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Termos de uso, abre em uma nova guia"
                    onClick={() => setActive(true)}
                  >
                    <MyAnchor className="termos-de-uso">
                      Termos de Uso <IconNewWindow width="1rem" height="1rem" />
                    </MyAnchor>
                    <ArrowImg src={Arrow} alt="Seta" />
                  </PrivacyText>
                )}
                {window.location.search.includes("shellbox") ? (
                  <PrivacyText
                    onClick={() => {
                      try {
                        // @ts-ignore
                        WebViewJavascriptInterface.openExternalLink(
                          "https://www.shell.com.br/politica-de-privacidade.html"
                        );
                      } catch (_) {
                        window.open(
                          "https://www.shell.com.br/politica-de-privacidade.html",
                          "_blank",
                          "noopener"
                        );
                        window.focus();
                      }
                      setActive(true);
                    }}
                  >
                    <MyAnchor className="politica-de-privacidade">
                      Política de Privacidade
                    </MyAnchor>
                  </PrivacyText>
                ) : (
                  <PrivacyText
                    href="https://www.shell.com.br/politica-de-privacidade.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Política de Privacidade, abre em uma nova guia"
                    onClick={() => setActive(true)}
                  >
                    <MyAnchor className="politica-de-privacidade">
                      Política de Privacidade{" "}
                      <IconNewWindow width="1rem" height="1rem" />
                    </MyAnchor>
                    <ArrowImg src={Arrow} alt="Seta" />
                  </PrivacyText>
                )}
                {window.location.pathname === "/" ||
                  window.location.pathname.includes("beneficios-app-shell-box") ||
                  window.location.pathname.includes("lubrificantes") ||
                  window.location.pathname.includes(
                    "ofertas-shell-select"
                  ) ? null : isShellbox ? (
                    <PrivacyText
                      onClick={() => {
                        if (
                          localStorage.getItem("query_shell") !== "null" &&
                          localStorage.getItem("query_shell")?.length > 1
                        ) {
                          history.push(
                            `${getSlug().url
                            }/regulamento?shellbox=${localStorage.getItem(
                              "query_shell"
                            )}&token=${localStorage.getItem("external_token")}`
                          );
                        } else {
                          history.push(`${getSlug().url}/regulamento`);
                        }
                      }}
                    >
                      <MyAnchor>Regulamento</MyAnchor>
                      <ArrowImg src={Arrow} alt="Seta" />
                    </PrivacyText>
                  ) : (
                  <PrivacyText
                    // onClick={() => history.push(`${getSlug().url}/regulamento`)}
                  >
                    <Link tabIndex={0} to={`${getSlug().url}/regulamento`}>
                      Regulamento
                    </Link>
                    <ArrowImg src={Arrow} alt="Seta" />
                  </PrivacyText>
                )}
                {window.location.pathname === "/" ||
                  window.location.pathname.includes("beneficios-app-shell-box") ||
                  window.location.pathname.includes("lubrificantes") ||
                  window.location.pathname.includes(
                    "ofertas-shell-select"
                  ) ? null : isShellbox ? (
                    <PrivacyText
                      onClick={() => {
                        if (
                          localStorage.getItem("query_shell") !== "null" &&
                          localStorage.getItem("query_shell")?.length > 1
                        ) {
                          history.push(
                            `${getSlug().url
                            }/duvidas?shellbox=${localStorage.getItem(
                              "query_shell"
                            )}&token=${localStorage.getItem("external_token")}`
                          );
                        } else {
                          history.push(`${getSlug().url}/duvidas`);
                        }
                      }}
                    >
                      <MyAnchor>Dúvidas</MyAnchor>
                      <ArrowImg src={Arrow} alt="Seta" />
                    </PrivacyText>
                  ) : (
                  <PrivacyText
                    // onClick={() => history.push(`${getSlug().url}/duvidas`)}
                  >
                    <Link to={`${getSlug().url}/duvidas`}>Dúvidas</Link>
                    <ArrowImg src={Arrow} alt="Seta" />
                  </PrivacyText>
                )}
              </FirstColumn>
            </FirstLine>
            <SecondLine>
              <Container isShellbox={isShellbox}>
                <ThumbShell src={ConchaShell} alt="Ícone concha" />
                <CopyrightText>
                  Copyright 2022 - Todos os direitos reservados a Shell
                </CopyrightText>
              </Container>
              {getSlug().url.includes("junte-troque") &&
                (isPromoFooter === true ? (
                  <TextInfoCustom>
                    Ação de venda de produtos exclusivos com e sem desconto válida
                    de 30.08.2022 a 09.04.2024. *1 concha a cada R$ 30,00 para
                    gasolina, gás natural veícular ou etanol; e 1 concha a cada R$
                    60,00 para diesel. Consulte as condições de participação,
                    Postos e Lojas participantes e o Regulamento completo no site{" "}
                    {
                      <LinkAccessibility
                        href="https://www.shell.com.br/promocaoshell"
                        isNewTab
                      >
                        www.shell.com.br/promocaoshell
                      </LinkAccessibility>
                    }
                    . Imagens meramente ilustrativas. *Em um mesmo cupom/nota
                    fiscal ”. A marca Shell é licenciada à Raizen S/A.{" "}
                    {/* <a
                      tabIndex={0}
                      aria-label="Clique aqui para abrir em uma nova guia"
                      rel="noopener noreferrer"
                      onClick={() => {
                        handleRedeemSelectedProduct(31)
                      }}
                    >
                      Clique aqui
                    </a>
                    {" "}para checar outras formas de adquirir o par de ingressos para o GP de São Paulo. */}
                  </TextInfoCustom>
                ) : (
                  <TextInfoCustom>
                    Ação de venda de produtos exclusivos com e sem desconto válida
                    de 30.08.2022 a 09.04.2024. *1 concha a cada R$ 30,00 para
                    gasolina, gás natural veícular ou etanol; e 1 concha a cada R$
                    60,00 para diesel. Consulte as condições de participação,
                    Postos e Lojas participantes e o Regulamento completo no site{" "}
                    {
                      <LinkAccessibility
                        href="https://www.shell.com.br/promocaoshell"
                        isNewTab
                      >
                        www.shell.com.br/promocaoshell
                      </LinkAccessibility>
                    }
                    . Imagens meramente ilustrativas. *Em um mesmo cupom/nota
                    fiscal ”. A marca Shell é licenciada à Raizen S/A.{" "}
                    {/* <a
                      tabIndex={0}
                      aria-label="Clique aqui para abrir em uma nova guia"
                      rel="noopener noreferrer"
                      onClick={() => {
                        handleRedeemSelectedProduct(31)
                      }}
                    >
                      Clique aqui
                    </a>
                    {" "}para checar outras formas de adquirir o par de ingressos para o GP de São Paulo. */}
                    {/* Ação de venda de produtos exclusivos com e sem desconto
                    válida de 30.08.2022 a 31.03.2023. *1 concha a cada R$ 30,00
                    para gasolina, gás natural veícular ou etanol; e 1 concha
                    a cada R$ 60,00 para diesel. Consulte as condições de
                    participação, Postos e Lojas participantes e o Regulamento
                    completo no site {" "}
                    {
                      <LinkAccessibility
                        href="https://www.shell.com.br/promocaoshell"
                        isNewTab
                      >
                        www.shell.com.br/promocaoshell
                      </LinkAccessibility>
                    }.
                    Imagens meramente ilustrativas.
                    *Em um mesmo cupom/nota fiscal ”. A marca Shell é licenciada à Raizen S/A */}
                  </TextInfoCustom>
                ))}
              {window.location.pathname.includes("beneficios-app-shell-box") &&
                (isLoading ? (
                  <Spinner />
                ) : (
                  <FooterText
                    dangerouslySetInnerHTML={{
                      __html: benefitsContent?.texto_rodape,
                    }}
                  />
                ))}
            </SecondLine>
          </>
        )}
      {/* SSO */}
      <form
        action={`https://${item?.redirectURL || "shelljunte-troque.shop"
          }?acesso=2&webview=${window.location.search.includes("shellbox") ? 1 : 0
          }&produto=${productSSO?.productId}`}
        style={{ display: "none" }}
        method="post"
        target="_self"
        id="formFooter"
      >
        <input
          type="hidden"
          name="token"
          id="token"
          value={`Bearer ${item?.token}`}
        ></input>
        <input type="hidden" name="acesso" value={2}></input>
        <input
          type="hidden"
          name="webview"
          id="webview"
          value={window.location.search.includes("shellbox") ? 1 : 0}
        ></input>
        <input
          type="hidden"
          name="produto"
          id="produto"
          value={productSSO?.productId}
        ></input>
      </form>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65.5px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0;
    border-top: 1px solid #bdbdbd;
    border-bottom: none;
  }
`;

const TextFirstLine = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;

  > svg {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 59px;
    border-bottom: 1px solid #bdbdbd;
    width: 100%;

    > svg {
      display: inline-flex;
      right: 1rem;
      position: absolute;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const PrivacyText = styled(TextFirstLine)`
  font-family: ${(props) => props.theme.Shell.Bold};
  text-decoration: none;
  margin-left: 10%;

  &:hover,
  :focus {
    a {
      color: ${(props) => props.theme.color.red};
      border-color: ${(props) => props.theme.color.red};
    }
  }
  > svg {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 5%;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin-left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 59px;

    > svg {
      display: inline-flex;
      right: 1rem;
      position: absolute;
    }
  }
`;

const HelpText = styled.div`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  margin-left: 10%;
`;

const HelpMobile = styled(PrivacyText)`
  flex-direction: column;
  height: unset;
  max-height: 8rem;
  align-items: baseline;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const SecondLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  width: 100%;
  flex: 1;
  padding: 17px 100px;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    flex-direction: column-reverse;
    padding: 0;
    height: auto;
    margin-bottom: ${(props) => props.isAnoTodo && "5rem"};
  }
`;

const Container = styled.div`
  display: flex;
  padding-bottom: ${(props) => (props.isShellbox ? "4rem" : "0")};
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1rem;
    padding-left: 3.5rem;
    padding-bottom: ${(props) => (props.isShellbox ? "4rem" : "1rem")};
  }

  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-left: 17px;
    padding: 1rem 0;
    padding-bottom: ${(props) => (props.isShellbox ? "4rem" : "1rem")};
  }
  @media (max-width: ${(props) => props.theme.queries.i5}) {
    width: 90%;
    padding: 1rem 0;
    padding-bottom: ${(props) => (props.isShellbox ? "4rem" : "1rem")};
  }
`;

const ThumbShell = styled.img`
  display: flex;
  margin-right: 1rem;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    margin-right: 0.5rem;
  }
`;

const CopyrightText = styled.span`
  font-family: ${(props) => props.theme.Shell.Medium};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;
  display: flex;
  flex: 1;
  align-items: center;

  @media (max-width: ${(props) => props.theme.queries.small}) {
    font-size: 12px;
  }
`;

const TextInfo = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #58595b;
  flex: 0.9;
  margin-right: 100px;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    margin: 0;
    padding: 1.5rem;
    flex: 1;
    border-bottom: 1px solid #bdbdbd;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 13px;
    line-height: 20px;
  }

  form {
    display: inline;
  }
  > a,
  button {
    color: #58595b;
    text-decoration: underline;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
    &:hover,
    :focus {
      color: #e98624;
    }

    & > svg {
      margin-bottom: -2px;
    }
  }
`;

const FooterText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #58595b;
  flex: 0.9;
  margin-right: 100px;
`;

const TextInfoCustom = styled(TextInfo)`
  font-family: ${(props) => props.theme.Shell.Medium};
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;
  border-bottom: 2px solid transparent;
  transition: 0.3s;

  &:hover {
    color: ${(props) => props.theme.color.red};
    transition: 0.3s;
    border-bottom: 2px solid ${(props) => props.theme.color.red};
  }

  &:focus {
    color: ${(props) => props.theme.color.red};
    transition: 0.3s;
    border-bottom: 2px solid ${(props) => props.theme.color.red};
  }

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 500;
  }
`;

const MyAnchor = styled.a`
  text-decoration: none;
  font-family: ${(props) => props.theme.Shell.Bold};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #58595b;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  text-decoration: none;

  svg {
    margin-bottom: -2px;
  }

  /* &:hover, :focus {
    color: ${(props) => props.theme.color.red};
    transition: 0.3s;
    border-bottom: 2px solid ${(props) => props.theme.color.red};
  } */

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 500;
    padding-bottom: 2px;
  }
`;

const ArrowImg = styled.img`
  display: none;
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    display: block;
    margin-right: 20px;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  width: 100%;
  max-width: 43.75rem;

  /* @media (max-width: 1280px) {
    flex: 1;
  } */

  @media (max-width: 768px) {
    display: block;
    flex: none;
  }
`;

const SecondColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 100px;
  flex-direction: row;
  align-items: center;

  &.hidden {
    display: none;
  }

  @media (max-width: 1365px) {
    padding-right: 50px;
    flex: 0.8;
  }

  @media (max-width: 768px) {
    display: block;
    flex: none;
    padding-right: 100px;
  }
`;

const TextHelp = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #58595b;
  border-left: 1px solid #58595b;
  padding-left: 10px;
  margin-left: 10px;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    border-left: none;
    font-size: 16px;
    line-height: 20px;
  }
`;

const TitleTextHelp = styled.span`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  margin-left: 10%;
  > svg {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.queries.lg}) {
    margin-left: 5%;
  }
  @media (max-width: ${(props) => props.theme.queries.medium}) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 59px;

    > svg {
      display: inline-flex;
      right: 1rem;
      position: absolute;
    }
  }
`;

const LinkMobile = styled.span`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: #58595b;
  border-bottom: 2px solid transparent;
  transition: 0.3s;

  @media (max-width: ${(props) => props.theme.queries.medium}) {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 500;
  }
`;
function getStorageProductSSO() {
  throw new Error("Function not implemented.");
}
