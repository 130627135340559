import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "configureStore";
import { Box, Tab, TabList } from "@chakra-ui/core";
import { CustomTab } from "modules/common/UI";
import { getSlug } from "utils/helper";

const LinksPromotionResponsive = (props) => {
  const isAuthenticated = useStore((state) => state.auth.isAuthenticated);

  return (
    <Box>
      {isAuthenticated && (
        <WrapTab>
          <CustomTab defaultIndex={-1}>
            <TabList>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url
                      }/minhas-conchas?shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    props.history.push(`${getSlug().url}/minhas-conchas`);
                  }
                }}
              >
                MINHAS CONCHAS
              </Tab>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url}?shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    props.history.push(`${getSlug().url}`);
                  }
                }}
              >
                SOBRE A PROMOÇÃO
              </Tab>
              <Tab
                tabIndex={0}
                onClick={() => {
                  if (
                    localStorage.getItem("query_shell") !== "null" &&
                    localStorage.getItem("query_shell")?.length > 1
                  ) {
                    props.history.push(
                      `${getSlug().url
                      }/meus-resgates?shellbox=${localStorage.getItem(
                        "query_shell"
                      )}`
                    );
                  } else {
                    window.location.pathname.includes("junte-e-troque-2021") ||
                      window.location.pathname.includes("junte-e-troque-2022") ? (
                      props.history.push(`${getSlug().url}/meus-pedidos`)
                    ) : (
                      props.history.push(`${getSlug().url}/meus-resgates`)
                    )
                  }
                }}
              >
                {
                  window.location.pathname.includes("junte-e-troque-2021") ||
                  window.location.pathname.includes("junte-e-troque-2022") ? 
                    "MEUS PEDIDOS" :
                    "MEUS RESGATES"
                }
              </Tab>
            </TabList>
          </CustomTab>
        </WrapTab>
      )}
    </Box>
  );
};

export default withRouter(LinksPromotionResponsive);

const WrapTab = styled(Box)`
  display: none;
  @media (max-width: ${(props) => props.theme.queries.small}) {
    display: block;
  }
  .css-w6ysom {
    margin-bottom: 0px;
  }
`;
