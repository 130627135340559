import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getSlug } from "utils/helper";
import useWindowSize from "hooks/useWIndowSize";
import { ShellValueLabel } from "../Home/Webdoor/ShellValueLabel";
import { Box } from "@chakra-ui/core";
import { useActions, useStore } from "configureStore";
import { GetUrlShop } from "services/shell";
import Cookies from "js-cookie";

export default function NavigationTabs() {
  const slug = getSlug().url;
  const { width } = useWindowSize();
  const isShellbox = window.location.search.includes("shellbox");
  const getShells = useActions((action) => action.anoTodo.getShells);
  const setActive = useActions((action) => action.loading.setActive);
  const [item, setItem] = useState(null);
  const isAuth = useStore((state) => state.auth.isAuthenticated)
  const clickCount = useStore((state) => state.anoTodo.buttonClickCount);
  const setClickCount = useActions(
    (action) => action.anoTodo.setButtonClickCount
  );

  useEffect(() => {
    if (isAuth) {
      getShells();
    }
  }, [getShells, isAuth, isShellbox]);

  async function getSSO() {
    const cookieTokenName = 'shell-sso-token'
    const cookieURLName = 'shell-sso-redirectURL'
    
    if (clickCount > 0) {
      setActive(true);
      try {
        const cookieToken = Cookies.get(cookieTokenName)
        const cookieURL = Cookies.get(cookieURLName)

        if (
          (cookieToken !== "undefined" && cookieToken !== "" && Boolean(!!cookieToken)) &&
          (cookieURL !== "undefined" && cookieURL !== "" && Boolean(!!cookieURL))
        ) {
          console.log("Retrieving cookies...")
          setItem({
            token: cookieToken,
            redirectURL: cookieURL
          })
        } else {
          const res = await GetUrlShop();
          console.log("Creating cookies...")
          Cookies.set(cookieTokenName, res?.data?.data.token, { expires: (res.data.data.expiresIn / 60) / 1440 })
          Cookies.set(cookieURLName, res?.data?.data.redirectURL, { expires: (res.data.data.expiresIn / 60) / 1440 })
          if (res?.data) {
            setItem({
              ...res.data.data,
            });
          }
        }
        } catch (err) {
        console.error(err);
        setClickCount(1)
      }
    }
    setClickCount(0);
  }

  useEffect(() => {
    if (item?.redirectURL) {
      const form = document.getElementById("formPedidos") as any;
      form?.submit();
    }
  }, [item]);

  function formSSORender() {
    return (
      // SSO
      <form
        action={`https://${item?.redirectURL || "shelljunte-troque.shop"
          }?acesso=1&webview=${window.location.search.includes("shellbox") ? 1 : 0
          }&tela=1`}
        style={{ display: "none" }}
        method="post"
        target="_self"
        id="formPedidos"
      >
        <input
          type="hidden"
          name="token"
          id="token"
          value={`Bearer ${item?.token}`}
        ></input>
        <input
          type="hidden"
          name="webview"
          id="webview"
          value={window.location.search.includes("shellbox") ? 1 : 0}
        ></input>
        <input type="hidden" name="acesso" value={1}></input>
        <input type="hidden" name="tela" value={1}></input>
      </form>
    );
  }

  if (width <= 768) {
    if (isAuth) {
      return (
        <Box
          d={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          maxW={["6.75rem", "10rem"]}
        >
          <ShellValueLabel />
          <LinkPedidos
            tabIndex={0}
            onClick={getSSO}
            className={`pedidos ${clickCount < 1 ? `disabled` : ``}`}
          >
            meus pedidos
          </LinkPedidos>
          {formSSORender()}
        </Box>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Container>
        <Box
          d={isAuth ? "flex" : "none"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"flex-end"}
          mb={"21px"}
          boxSizing={"border-box"}
        >
          <ShellValueLabel />
          <LinkPedidos
            tabIndex={0}
            onClick={getSSO}
            className={`pedidos ${clickCount < 1 ? `disabled` : ``}`}
          >
            meus pedidos
          </LinkPedidos>
          {formSSORender()}
        </Box>
        <Box display={"flex"} marginTop={!isAuth && "1.25rem"}>
          <NavItem
            to={`${slug}/todos-produtos`}
            className={
              window.location.pathname.includes("todos-produtos") && "active"
            }
          >
            Todos os Produtos
          </NavItem>
          <NavItem
            to={`${slug}/como-funciona`}
            className={
              window.location.pathname.includes("como-funciona") && "active"
            }
          >
            Como Funciona
          </NavItem>
          <NavItem
            to={`${slug}/postos-participantes`}
            className={
              window.location.pathname.includes("postos-participantes") &&
              "active"
            }
          >
            Postos e Lojas Participantes
          </NavItem>
        </Box>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  max-width: 753px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.25);
`;

const NavItem = styled(Link)`
  color: #fff;
  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 0.75rem;
  line-height: 100%;
  text-decoration: none;

  padding-bottom: 0.875rem;
  border-bottom: 3px solid transparent;
  transition: 0.2s;

  &.active {
    border-color: rgba(200, 200, 200, 0.25);
  }

  &:hover,
  :focus {
    /* border-color: rgba(200, 200, 200, 0.25); */
    color: ${(props) => props.theme.color.yellow};
  }

  & + a {
    margin-left: 4.5rem;
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    font-size: 0.938rem;
  }
`;

const LinkPedidos = styled.a`
  font-family: ${(props) => props.theme.Shell.Bold};
  font-size: 0.563rem;
  line-height: 120%;
  color: ${(props) => props.theme.color.yellow};

  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  margin-left: 1.125rem;
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 2px solid transparent;

  &:hover,
  :focus {
    /* text-decoration: underline; */
    border-color: ${(props) => props.theme.color.yellow};
    &.disabled {
      filter: grayscale(1);
      border: none;
      cursor: default;
    }
  }

  &.disabled {
    filter: grayscale(1);
    border: none;
    cursor: default;
  }

  @media (min-width: ${(props) => props.theme.queries.lg}) {
    font-size: ${(props) => props.theme.fontSize.normal};
  }
`;
