import React from "react"
import styled from "styled-components"
import { QrCodeSection } from "modules/promoGrandeGp/components/Home/QrCode"
import { GpVideoSection } from "modules/promoGrandeGp/components/Home/GpVideoSection"
import { SorteioSection } from "modules/promoGrandeGp/components/Home/SorteioSection"
import { RedeemTickets } from "modules/promoGrandeGp/components/Home/RedeemTickets"

export default function Home() {
  return (
    <HomeContainer>
      <QrCodeSection />
      <GpVideoSection />
      <SorteioSection />
      <RedeemTickets />
    </HomeContainer>
  )
}

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;

  width: 90%;
  max-width: 1190px;

  margin: 1.25rem auto 1.875rem;

  @media (min-width: ${props => props.theme.queries.desk}) {
    gap: 75px;
    margin: 5rem auto 4.625rem;
  }
`