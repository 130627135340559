import React, { useEffect } from "react";
import styled from "styled-components";
import { DoubleBannerHub } from "../CityPromotions/DoubleBannerHub";
import FinishedPromotions from "../PromosEncerradas/FinishedPromotions";
import { useActions, useStore } from "configureStore";
import { CarouselWebdoorBanner } from "../CityPromotions/CarouselBanner";

// Imagens
import PromoImgJunteTroque from "assets/images/hub/PromocoesCidade/encerrada-junte-troque.png";
import PromoImgShellNaCaixa from "assets/images/hub/PromocoesCidade/encerrada-shell-na-caixa.jpg";
import PromoImgEmbarque from "assets/images/hub/PromocoesCidade/encerrada-embarque-sonho.png";
import PromoJunteGanhe from "assets/images/hub/PromocoesCidade/encerrada-junte-ganhe.png";
import PromoMenos15 from "assets/banners/PromosEncerradas/promo-menos15.png";
import PromoJT2021 from "assets/images/hub/PromocoesCidade/jt-2021.png";

interface ImageListProps {
  url: string;
  alt: string;
  href?: string;
}

export default function HubHome() {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const getWebdoorDoubleBanners = useActions((action) => action.hub.getWebdoorCarousel)
  const gethubBanners = useActions((action) => action.anoTodo.getHubBanner);
  const hubBanners = useStore((state) => state.anoTodo.hubBanner);
  const imageList: ImageListProps[] = [
    {
      url: hubBanners?.imagem_encerrada,
      alt: "Promoção Junte e Troque Curta a vida on estiver",
    },
    {
      url: PromoJT2021,
      alt: "promoção Junte e Troque 2021",
    },
    {
      url: PromoMenos15,
      alt: "promoção Menos15",
    },
    {
      url: PromoJunteGanhe,
      alt: "promoção Junte e ganhe",
    },
    {
      url: PromoImgJunteTroque,
      alt: "promoção Junte troque",
    },
    {
      url: PromoImgShellNaCaixa,
      alt: "promoção shell na caixa",
    },
    {
      url: PromoImgEmbarque,
      alt: "promoção embarque num sonho",
    },
  ];

  useEffect(() => {
    gethubBanners();
    getWebdoorDoubleBanners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <DoubleBannerHub />
      {/* <CarouselWebdoorBanner  /> */}
      <FinishedPromotions imageList={imageList} />

      {/* <CarouselModal
        isOpen={isOpen}
        onClose={onClose}
        imageLarge={PromoDesktop}
        imageMobile={PromoMobile}
      /> */}
    </main>
  );
}

const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;

  @media (max-width: 1200px) {
    padding: 0 1.5em;
  }
`;
