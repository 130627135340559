import React from "react";
import {
  SorteioCardIcon,
  SorteioSectionCard,
  SorteioSectionContainer,
  SorteioSectionTitle,
  WrapperSectionCardMobile,
} from "../../Home/SorteioSection/styles";

import IconMobile from "assets/icons/onda/mobile.svg";
import IconBomba from "assets/images/grandeGp/bomba.svg";
import IconResgate from "assets/icons/troca.svg";
import IconCalendario from "assets/icons/calendario.svg";
import { RenderCarousel } from "../../Home/SorteioSection/CarouselRenderer";
import useWindowSize from "hooks/useWIndowSize";

export function RedeemTicketsCardsSection() {
  const { width } = useWindowSize();
  const isMobile375 = width <= 375;

  return (
    <SorteioSectionContainer>
      <SorteioSectionTitle>
        Veja os passos para resgatar os ingressos
      </SorteioSectionTitle>

      {isMobile375 ? (
        <WrapperSectionCardMobile>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconMobile} alt="Ícone de um celular" />
            </SorteioCardIcon>
            <span>passo 1</span>
            <strong>Baixe o aplicativo Shell Box</strong>
            <p>
              Disponível para iOS e Android.
              <br />
              <br />
              Não se esqueça de dar o aceite para participar das promoções.
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
            </SorteioCardIcon>
            <span>passo 2</span>
            <strong>Acumule conchas</strong>
            <p>
              A cada R$30 em abastecimento pago com o aplicativo Shell Box,
              ganhe 1 concha. Abastecimento com Shell V-Power e pagamento com
              Mastercard valem 1 concha extra a partir de R$60.
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconResgate} alt="Ícone de trevo de quatro folhas" />
            </SorteioCardIcon>
            <span>passo 3</span>
            <strong>Resgate pela Junte&Troque</strong>
            <p>
              Para resgatar o combo do Grande Prêmio SP 2023 você precisa de 100
              conchas + um valor a se investido em dinheiro (variável por lote).
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconCalendario} alt="Ícone de bandeira quadriculada" />
            </SorteioCardIcon>
            <span>passo 4</span>
            <strong>Fique atento às datas dos lotes</strong>
            <p>
              Serão 5 lotes disponíveis toda quarta-feira, às 12h, a partir do
              dia 27 de setembro. Boa sorte!
            </p>
          </SorteioSectionCard>
        </WrapperSectionCardMobile>
      ) : (
        <RenderCarousel>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconMobile} alt="Ícone de um celular" />
            </SorteioCardIcon>
            <span>passo 1</span>
            <strong>Baixe o aplicativo Shell Box</strong>
            <p>
              Disponível para iOS e Android.
              <br />
              <br />
              Não se esqueça de dar o aceite para participar das promoções.
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
            </SorteioCardIcon>
            <span>passo 2</span>
            <strong>Acumule conchas</strong>
            <p>
              A cada R$30 em abastecimento pago com o aplicativo Shell Box,
              ganhe 1 concha. Abastecimento com Shell V-Power e pagamento com
              Mastercard valem 1 concha extra a partir de R$60.
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconResgate} alt="Ícone de trevo de quatro folhas" />
            </SorteioCardIcon>
            <span>passo 3</span>
            <strong>Resgate pela Junte&Troque</strong>
            <p>
              Para resgatar o combo do Grande Prêmio SP 2023 você precisa de 100
              conchas + um valor a se investido em dinheiro (variável por lote).
            </p>
          </SorteioSectionCard>
          <SorteioSectionCard>
            <SorteioCardIcon>
              <img src={IconCalendario} alt="Ícone de bandeira quadriculada" />
            </SorteioCardIcon>
            <span>passo 4</span>
            <strong>Fique atento às datas dos lotes</strong>
            <p>
              Serão 5 lotes disponíveis toda quarta-feira, às 12h, a partir do
              dia 27 de setembro. Boa sorte!
            </p>
          </SorteioSectionCard>
        </RenderCarousel>
      )}
      <span>* Confira o regulamento da promoção e saiba mais.</span>
    </SorteioSectionContainer>
  );
}
